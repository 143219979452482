import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { SignupComponent } from "./components/signup/signup.component";
import { LisitngViewComponent } from "./components/lisitng-view/lisitng-view.component";
import { ChatComponent } from "./components/chat/chat.component";
import { from } from 'rxjs';
import { BookappointmentComponent } from "./components/bookappointment/bookappointment.component";
import { BookhivtestComponent } from "./components/bookhivtest/bookhivtest.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { AppointmentComponent } from "./components/dashboard/appointment/appointment.component";
import { FacilityComponent } from "./components/dashboard/facility/facility.component";
import { HivComponent } from "./components/dashboard/hiv/hiv.component";
import { ChatClientComponent } from "./components/dashboard/chat-client/chat-client.component";
import { SummaryDashboardComponent } from './components/dashboard/summary-dashboard/summary-dashboard.component';
import { AuthGuard } from "./guards/auth.guard";
import { ViewEditFacilityComponent } from "./components/dashboard/facility/view-edit-facility/view-edit-facility.component";
import { AddNewFacilityComponent } from "./components/dashboard/facility/add-new-facility/add-new-facility.component";
import { AdvertisementsComponent } from "./components/dashboard/advertisements/advertisements.component";
import { AddAdvertisementComponent } from "./components/dashboard/advertisements/add-advertisement/add-advertisement.component";
import { ViewEditAdvertisementComponent } from "./components/dashboard/advertisements/view-edit-advertisement/view-edit-advertisement.component"
import { BannersectionsComponent } from "./components/dashboard/bannersections/bannersections.component";
import { AddBannersectionComponent } from "./components/dashboard/bannersections/add-bannersection/add-bannersection.component";
import { ViewEditBannersectionComponent } from "./components/dashboard/bannersections/view-edit-bannersection/view-edit-bannersection.component"
import { ServicesComponent } from "./components/dashboard/services/services.component";
import { AddServicesComponent } from "./components/dashboard/services/add-services/add-services.component";
import { ViewEditServicesComponent } from "./components/dashboard/services/view-edit-services/view-edit-services.component";
import { UploadImageComponent } from './components/dashboard/advertisements/upload-image/upload-image.component';
import { BasicHivComponent } from "./components/basic-hiv/basic-hiv.component";
import { BasicsComponent } from "./components/dashboard/basics/basics.component";
import { AddBasicsComponent } from "./components/dashboard/basics/add-basics/add-basics.component";
import { ViewEditBasicsComponent } from "./components/dashboard/basics/view-edit-basics/view-edit-basics.component";
import { BannerlistsComponent } from "./components/dashboard/bannerlists/bannerlists.component";
import { AddBannerListComponent } from "./components/dashboard/bannerlists/add-banner-list/add-banner-list.component";
import { ViewEditBannerListComponent } from "./components/dashboard/bannerlists/view-edit-banner-list/view-edit-banner-list.component";
import { UploadBannersForListComponent } from './components/dashboard/bannerlists/upload-banners-for-list/upload-banners-for-list.component';
import { UploadMainBannerSectionComponent } from './components/dashboard/bannersections/upload-main-banner-section/upload-main-banner-section.component';
import { AppInfoComponent } from './components/dashboard/app-info/app-info.component';
import { OrderComponent } from './components/order/order.component';
import { AboutComponent } from './components/about/about.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutAdminComponent } from './components/dashboard/about-admin/about-admin.component';
import { AddAboutAdminComponent } from './components/dashboard/about-admin/add-about-admin/add-about-admin.component';
import { ViewEditAboutAdminComponent } from './components/dashboard/about-admin/view-edit-about-admin/view-edit-about-admin.component';
import { PrivacyAdminComponent } from './components/dashboard/privacy-admin/privacy-admin.component';
import { AddPrivacyAdminComponent } from './components/dashboard/privacy-admin/add-privacy-admin/add-privacy-admin.component';
import { ViewEditPrivacyAdminComponent } from './components/dashboard/privacy-admin/view-edit-privacy-admin/view-edit-privacy-admin.component';
import { ContactAdminComponent } from './components/dashboard/contact-admin/contact-admin.component';
import { AddContactAdminComponent } from './components/dashboard/contact-admin/add-contact-admin/add-contact-admin.component';
import { ViewEditContactAdminComponent } from './components/dashboard/contact-admin/view-edit-contact-admin/view-edit-contact-admin.component';
import { DisclaimerAdminComponent } from './components/dashboard/disclaimer-admin/disclaimer-admin.component';
import { AddDisclaimerAdminComponent } from './components/dashboard/disclaimer-admin/add-disclaimer-admin/add-disclaimer-admin.component';
import { ViewEditDisclaimerAdminComponent } from './components/dashboard/disclaimer-admin/view-edit-disclaimer-admin/view-edit-disclaimer-admin.component';
import { CondomDetailsComponent } from './components/dashboard/condom-details/condom-details.component';
import { UserDetailsComponent } from './components/dashboard/user-details/user-details.component';
import { CounselingDetailsComponent } from './components/dashboard/counseling-details/counseling-details.component';
import { RiskAssesmentComponent } from './components/dashboard/risk-assesment/risk-assesment.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'signup', component: SignupComponent, data: { title: 'Sign Up' } },
  { path: 'home', component: HomeComponent, data: { title: 'Home Page' } },
  { path: 'listing-view', component: LisitngViewComponent, data: { title: 'Listing View' } },
  { path: 'basic-hiv', component: BasicHivComponent, data: { title: 'Basics for HIV' } },
  { path: 'about', component: AboutComponent, data: { title: 'About' } },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' } },
  { path: 'disclaimer', component: DisclaimerComponent, data: { title: 'Disclaimer' } },
  { path: 'contact', component: ContactComponent, data: { title: 'Contact' } },
  {
    path: 'chat', component: ChatComponent, data: { title: 'chats' }
  },
  {
    path: 'order', component: OrderComponent, data: { title: 'order' }
  },
  { path: 'appointment', component: BookappointmentComponent, data: { title: 'Book Appointment' } },
  { path: 'bookHIVTest', component: BookhivtestComponent, data: { title: 'Book HIV Test' } },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: {
      title: 'Dashboard', permission: {
        only: ['Admin', 'Counsellor'],
        redirectTo: 'home'
      }
    }, children: [
      { path: '', redirectTo: 'SummaryDashboard', pathMatch: 'full' },
      { path: 'appointment', component: AppointmentComponent, data: { title: 'Appointment Results' } },
      { path: 'condom', component: CondomDetailsComponent, data: { title: 'Condom Order Results' } },
      { path: 'risk', component: RiskAssesmentComponent, data: { title: 'Condom Order Results' } },
      { path: 'userlist', component: UserDetailsComponent, data: { title: 'User List Results' } },
      { path: 'hiv', component: HivComponent, data: { title: 'HIV Result' } },
      { path: 'counseling', component: CounselingDetailsComponent },
      {
        path: 'facility', component: FacilityComponent, data: { title: 'Facility Result' }
      },
      { path: 'chatClient', component: ChatClientComponent, data: { title: 'Chat With Client' } },
      { path: 'SummaryDashboard', component: SummaryDashboardComponent, data: { title: 'Summary Dashboard' } },
      { path: 'viewFacility/:id/:isEditable', component: ViewEditFacilityComponent },
      { path: 'editFacility/:id/:isEditable', component: ViewEditFacilityComponent },
      { path: 'addFacility', component: AddNewFacilityComponent },
      { path: 'advertisements', component: AdvertisementsComponent, data: { title: 'Advertisements' } },
      { path: 'addAdvertisement', component: AddAdvertisementComponent },
      { path: 'viewAdvertisement/:id/:isEditable', component: ViewEditAdvertisementComponent },
      { path: 'editAdvertisement/:id/:isEditable', component: ViewEditAdvertisementComponent },
      { path: 'uploadServiceBanners/:id', component: UploadImageComponent },
      { path: 'bannersections', component: BannersectionsComponent, data: { title: 'Bannersections' } },
      { path: 'addBannersection', component: AddBannersectionComponent },
      { path: 'viewBannersection/:id/:isEditable', component: ViewEditBannersectionComponent },
      { path: 'editBannersection/:id/:isEditable', component: ViewEditBannersectionComponent },
      { path: 'uploadMainBannerSection/:id', component: UploadMainBannerSectionComponent },
      { path: 'services', component: ServicesComponent },
      { path: 'addServices', component: AddServicesComponent },
      { path: 'viewServices/:id/:isEditable', component: ViewEditServicesComponent },
      { path: 'editServices/:id/:isEditable', component: ViewEditServicesComponent },
      { path: 'appInfo', component: AppInfoComponent },
      { path: 'basics', component: BasicsComponent },
      { path: 'addBasics', component: AddBasicsComponent },
      { path: 'viewBasics/:id/:isEditable', component: ViewEditBasicsComponent },
      { path: 'editBasics/:id/:isEditable', component: ViewEditBasicsComponent },

      

      { path: 'abouts', component: AboutAdminComponent },
      { path: 'addAbouts', component: AddAboutAdminComponent },
      { path: 'viewAbouts/:id/:isEditable', component: ViewEditAboutAdminComponent },
      { path: 'editAbouts/:id/:isEditable', component: ViewEditAboutAdminComponent },

      { path: 'privacyPolicys', component: PrivacyAdminComponent },
      { path: 'addPrivacyPolicys', component: AddPrivacyAdminComponent },
      { path: 'viewPrivacyPolicys/:id/:isEditable', component: ViewEditPrivacyAdminComponent },
      { path: 'editPrivacyPolicys/:id/:isEditable', component: ViewEditPrivacyAdminComponent },

      { path: 'contacts', component: ContactAdminComponent },
      { path: 'addContacts', component: AddContactAdminComponent },
      { path: 'viewContacts/:id/:isEditable', component: ViewEditContactAdminComponent },
      { path: 'editContacts/:id/:isEditable', component: ViewEditContactAdminComponent },

      { path: 'disclaimers', component: DisclaimerAdminComponent },
      { path: 'addDisclaimers', component: AddDisclaimerAdminComponent },
      { path: 'viewDisclaimers/:id/:isEditable', component: ViewEditDisclaimerAdminComponent },
      { path: 'editDisclaimers/:id/:isEditable', component: ViewEditDisclaimerAdminComponent },

      { path: 'bannerlists', component: BannerlistsComponent },
      { path: 'addBannerLists', component: AddBannerListComponent },
      { path: 'viewBannerLists/:id/:isEditable', component: ViewEditBannerListComponent },
      { path: 'editBannerLists/:id/:isEditable', component: ViewEditBannerListComponent },
      { path: 'uploadBannersForList/:id', component: UploadBannersForListComponent }


    ]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
