import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class HeaderService {
  visible: boolean = true;
  isVisibleChange: Subject<boolean> = new Subject<boolean>();
  constructor() { 
   }
  hide() { 
    this.isVisibleChange.next(false); 
  }
  show() { 
    this.isVisibleChange.next(true); 
  }
}
