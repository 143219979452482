import { Component, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { LocalStorage } from './helpers/local-storage';
import { HTTPStatus} from './helpers/httplistener';
import { AuthenticationService } from './services/authentication.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  previousUrl: string;
  currentUrl: string;
  HTTPActivity: boolean;
  infoSection: any = [];
  /* notificationsCount is a thing of header bell icon, so shoud move there later */
  notificationsCount: number = 0;
  constructor(private router: Router, private httpStatus: HTTPStatus, private authenticationService:AuthenticationService,private httpService: HttpClient,) {
    this.currentUrl = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.authenticationService.setKeyValLocal('previousUrl',this.previousUrl);
        this.authenticationService.setKeyValLocal('currentUrl', this.currentUrl);
        setTimeout(() => {
          window.scroll(0, 0);
        }, 1000);
      }
    });
  }
  ngOnInit() { 
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => { this.HTTPActivity = status; });
    
  }
  ngOnDestroy() {
 }
}
