import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-add-banner-list',
  templateUrl: './add-banner-list.component.html',
  styleUrls: ['./add-banner-list.component.scss']
})
export class AddBannerListComponent implements OnInit {
  rowData: any={};
  rowDataBannerListShow: boolean = false;
  columnDefs: Array<any> = [];
  client_name: any = environment.client_name;

  constructor(private router: Router, private route: ActivatedRoute,private httpService: HttpClient,private mScrollbarService: MalihuScrollbarService) { 
    this.columnDefs = [
      { headerName: 'Img ID', field: 'img_Id', edit: false, type:'input'},
      { headerName: 'Img Name', field: 'img_name',edit: true, type:'input'},
      { headerName: 'Img Link', field: 'img_link', edit: true, type:'input'}
    ];
  }

  ngOnInit() {
    this.listDataView();
  }
  listDataView() {

    this.httpService.get('/api/getBannerList').subscribe((res: any) => {
        // this.rowData = res;
      if (res.length == 0) {
        this.rowData.img_Id = 'IM1';
      }
      else {
        let length = res.length - 1;
        this.rowData.img_Id = 'IM' + (parseInt((res[length].img_Id).replace('IM', '')) + 1);
      }
       this.rowDataBannerListShow = true;
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }


  addNewBannerListData(event) {
    let obj = this.rowData;
    this.httpService.post('/api/saveBannerList', obj).subscribe((res: any) => {
      this.router.navigate(['/dashboard/bannerlists']);
     }, (err: HttpErrorResponse) => {
      console.log(err.message);
    })
  }
}
