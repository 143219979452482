
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable()
export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}

@Injectable()
export class HTTPListener implements HttpInterceptor {
  requestCounter: number;
  constructor(private status: HTTPStatus) {
    this.requestCounter = 0;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.status.setHttpStatus(true)
    this.requestCounter++;
    return next.handle(req).pipe(
      map(event => {
        return event;
      }),
      catchError(error => {
        return observableThrowError(error);
      }),
      finalize(() => {
        this.requestCounter--;
        if(this.requestCounter == 0) {
          this.status.setHttpStatus(false);
        }
      })
    )
  }
}