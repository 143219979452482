import { Component, OnInit } from '@angular/core';
import {Validators, FormBuilder, FormGroup} from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';

@Component({
  selector: 'app-view-edit-banner-list',
  templateUrl: './view-edit-banner-list.component.html',
  styleUrls: ['./view-edit-banner-list.component.scss']
})
export class ViewEditBannerListComponent implements OnInit {
  imgId: any;
  isEditable: boolean= false;
  title: string;
  rowData: any;
  rowDataBannerListShow: boolean = false;
  columnDefs: Array<any> = [];
  constructor(private router: Router, private route: ActivatedRoute,private httpService: HttpClient,private mScrollbarService: MalihuScrollbarService) { 
    this.columnDefs = [
      { headerName: 'Img ID', field: 'img_Id', edit: false, type:'input'},
      { headerName: 'Img Name', field: 'img_name',edit: true, type:'input'},
      { headerName: 'Img Link', field: 'img_link',edit: true, type:'input'}
    ];
    this.route.params.subscribe(params => {
      this.imgId = params['id'];
      this.isEditable = params['isEditable'] == 'true'? true: false;
      });
  }

  ngOnInit() {
    if (this.isEditable) {
      this.title = 'Edit Banner List';
    }
    else {
      this.title = 'View Banner List';
    }
    this.listDataView();
  }
  listDataView() {
    this.httpService.get('/api/getBannerList/' +this.imgId).subscribe((res: any) => {
      this.rowData = res[0];
      this.rowDataBannerListShow = true;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }

  updateBannerListData() {
    let obj = this.rowData;
    this.httpService.post('/api/setBannerList/'+ this.imgId, obj).subscribe((res: any) => {
      this.router.navigate(['/dashboard/bannerlists']);
     }, (err: HttpErrorResponse) => {
      console.log(err.message);
    })
  }

}
