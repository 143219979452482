import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { SortPipe } from "../../pipes/sort.pipe";
import { LocalStorage } from '../../helpers/local-storage';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../services/authentication.service';


@Component({
  selector: 'app-lisitng-view',
  templateUrl: './lisitng-view.component.html',
  styleUrls: ['./lisitng-view.component.scss']
})
export class LisitngViewComponent implements OnInit {
  getContactNo: any;
  userSettings: any = {};
  selectedLocationData: any;
  selectedProgramId: any;
  arrFacility: Array<any> = [];
  resultFound: Array<any> = [];
  selectedLocation: any = {};
  selectProgramListId: any;
  selectDistrict: any = false;
  allProgramList: any = [];
  getFilteredData: any = [];
  servicesOffered: any = [];
  allDistrictList: Array<any> = [];
  listResultPage: Number = 1;

  client_name: any;
  selectedFoundLocation: any = '';
  sectionInfo:any = [];
  constructor(private httpService: HttpClient, private modalService: NgbModal, private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.client_name = environment.client_name;
    this.httpService.get('/api/getappinfo').subscribe((res: any) => {
      this.sectionInfo = res[0];
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
    this.getSelectedSearchData();
    this.listDataView();
    this.allDistrictList = [{ "id": "Central", "name": "Central" }, { "id": "East", "name": "East" }, { "id": "New Delhi", "name": "New Delhi" }, { "id": "North Delhi", "name": "North Delhi" }, { "id": "North East", "name": "North East" }, { "id": "North West", "name": "North West" }, { "id": "Shahdara", "name": "Shahdara" },{ "id": "South Delhi", "name": "South Delhi" }, { "id": "South East", "name": "South East" }, { "id": "South West", "name": "South West" },{ "id": "West Delhi", "name": "West Delhi" }];
    this.userSettings = {
      "showSearchButton": false,
      "showRecentSearch": false,
      "geoCountryRestriction": ['in'],
      "showCurrentLocation": true,
      "inputString": this.selectedLocationData.description
    }
  };
  listDataView() {
    this.httpService.get('/api/getFacilities').subscribe((res: any) => {

      res.forEach(element => {
        if (element.isOwner == 'common' || element.isOwner == this.client_name) {
          this.getFilteredData.push(element);
        }
      });

      this.arrFacility = this.getFilteredData;
      this.loadResultData(this.selectProgramListId, false);
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }
  //   goToChat(facilityData) {
  //     this.router.navigate(['/chat', facilityData.nodalName]);
  // }


  autoCompleteCallback2(selectedData: any) {
    this.authenticationService.setKeyValLocal('selectedLocation', selectedData.data);
    this.selectedLocationData = selectedData.data;
    this.getCustomLocationDataObj();
  }
  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }
  goToBookAppointment(facility) {
    let obj = { 'facility': facility };
    this.authenticationService.setKeyValLocal('bookAppointmentData', obj);
    this.router.navigate(["/appointment"]);
  }
  goToBookHIVTest(facility) {
    this.authenticationService.setKeyValLocal('appointmentFacilityData', facility);
    this.router.navigate(["/bookHIVTest"]);
  }
  loadResultData(value, districtFlag) {
    this.getCustomLocationDataObj();
    this.resultFound = [];
    if (value) {
      let resultFoundTemp: any = [];
      resultFoundTemp = this.arrFacility.filter((filterVal) => {
        if (districtFlag) {

          this.userSettings['inputString'] = 'Delhi, India';
          this.userSettings = Object.assign({}, this.userSettings);
          this.selectedLocation.locality = this.selectDistrict;
          return filterVal.programID == value && filterVal.district == this.selectDistrict;
        }
        else {
          return filterVal.programID == value && this.getCurrentLocationInputFlag(filterVal);
        }
      });
      if (resultFoundTemp.length == 0) {
        if (!districtFlag) {
          resultFoundTemp = this.arrFacility.filter((filterVal) => {
            return filterVal.programID == value && this.searchByLocality(filterVal);
          });
          if (resultFoundTemp.length == 0) {
            resultFoundTemp = this.arrFacility.filter((filterVal) => {
              return filterVal.programID == value && this.searchByAdministrativeArea(filterVal);
            });
            this.selectedFoundLocation = this.selectedLocation.administrative_area_level_1 + '.';
            this.resultFound = resultFoundTemp;
          }
          else {
            this.selectedFoundLocation = this.selectedLocation.locality + '.';
            this.resultFound = resultFoundTemp;
          }
        }
        else {
          this.selectedFoundLocation = this.selectDistrict;
          this.resultFound = resultFoundTemp;
        }
      }
      else {
        if (districtFlag) {
          this.selectedFoundLocation = this.selectDistrict;
        }
        else {
          this.selectedFoundLocation = this.selectedLocation.postal_code != undefined ? this.selectedLocation.locality + ', ' + this.selectedLocation.postal_code + '.' : this.selectedLocation.locality + '.';
        }
        this.resultFound = resultFoundTemp;
      }
    }
    return false;
  }
  getResultListView(data, districtFilterFlag) {
    if (districtFilterFlag == false) {
      this.selectDistrict = false;
    }
    if (data == false) {
      districtFilterFlag = false;
    }
    this.authenticationService.setKeyValLocal('selectedProgramId', this.selectProgramListId);
    this.loadResultData(this.selectProgramListId, districtFilterFlag);
  }
  getSelectedSearchData() {
    this.selectedLocationData = this.authenticationService.getKeyValLocal('selectedLocation');
    this.selectProgramListId = this.authenticationService.getKeyValLocal('selectedProgramId');
    this.allProgramList = this.authenticationService.getKeyValLocal('programList');
    this.getCustomLocationDataObj();
  }
  getCustomLocationDataObj() {
    if (this.selectedLocationData == undefined || this.selectedLocationData == null) {
      this.selectedLocationData = {};
      this.selectedLocationData['address_components'] = [{ "long_name": "Delhi", "short_name": "Delhi", "types": ["locality", "political"] }, { "long_name": "Delhi", "short_name": "DL", "types": ["administrative_area_level_1", "political"] }, { "long_name": "India", "short_name": "IN", "types": ["country", "political"] }];
      this.selectedLocationData['description'] = 'Delhi, India';
    }
    if (this.allProgramList) {

      this.httpService.get('/api/programList').subscribe((data: any) => {
        this.allProgramList = data;
        this.authenticationService.setKeyValLocal('programList', this.allProgramList);
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      });
    }
    if (this.selectProgramListId) {
      if (this.allProgramList.length > 0) {
        this.selectedProgramId = this.allProgramList[0].ID;
      }
    }
    this.selectedLocationData.address_components.forEach(addComp => {
      this.selectedLocation[addComp.types[0]] = addComp.long_name;
    });
  }
  getCurrentLocationInputFlag(filterVal) {
    if (this.selectedLocation.postal_code != undefined) {
      return this.searchByPincode(filterVal);
    }
    else if (this.selectedLocation.locality != undefined) {
      return this.searchByLocality(filterVal);
    }
    else if (this.selectedLocation.administrative_area_level_1 != undefined) {
      return this.searchByAdministrativeArea(filterVal);
    }
  }
  searchByPincode(filterVal) {
    if (filterVal.pincode == this.selectedLocation.postal_code) {
      return true;
    }
    else {
      return false;
    }
  }
  searchByLocality(filterVal) {
    if (filterVal.city == this.selectedLocation.locality) {
      return true;
    }
    else {
      return false;
    }
  }
  searchByAdministrativeArea(filterVal) {
    if (filterVal.state == this.selectedLocation.administrative_area_level_1) {
      return true;
    }
    else {
      return false;
    }
  }
  // getValueforCall(val,contact){
  //   if(val == "" || val == undefined || val == null){
  //   this.getContactNo = contact;
  //   }
  //   else{
  //     this.getContactNo = val;
  //   }

  // }
}