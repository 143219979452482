import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(value: Array<string>, args: string): Array<string> {
    return value.sort(function (a:any, b:any) {
      if(a < b){
          return -1;
      }
      else if( a > b){
          return 1;
      }
      else{
          return 0;
      }
  });
  }

}
