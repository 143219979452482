import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  showFiller = false;
  private _opened: boolean = false;
 
  private _toggleSidebar() {
    this._opened = !this._opened;
  }
  constructor() {
  
   }
  ngOnInit() {

  }

}
