import { Component, OnInit, ViewChild, AfterViewChecked, OnDestroy, ElementRef } from '@angular/core';
import { Message } from '../../../models/message.model';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { AuthenticationService } from '../../../services/authentication.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalStorage } from '../../../helpers/local-storage';
import { ChatClientService } from '../../../helpers/chat-client.service';

@Component({
  selector: 'app-chat-client',
  templateUrl: './chat-client.component.html',
  styleUrls: ['./chat-client.component.scss']
})
export class ChatClientComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('scrollMe') myScrollContainer;
  chats: any;
  newUserChatRoomName: any = '';
  joinned: boolean = false;
  chatWith: any;
  newCounsellorSelected: any;
  socket: any;
  isLoggedInUser: boolean = false;
  userInfo: any;
  programList: any = [];
  chatListUsers: {};
  conversationId: any;
  messageList: Array<Message>;
  noMsg: boolean;
  receiveActiveObs: any;
  userList: Array<any> = [];
  currentOnline: boolean = false;
  receiveMessageObs: any;
  notification: any = { timeout: null };
  notify: boolean;
  msgData: any = {}
  showActive: boolean;
  isStartChat: boolean = false;

  constructor(private chatService: ChatClientService,
    private mScrollbarService: MalihuScrollbarService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private el: ElementRef,
    private router: Router) { }

  ngOnInit() {
    if (this.authenticationService.userToken() != undefined) {
      this.isLoggedInUser = true;
      let userData = this.authenticationService.getUserData();
      this.userInfo = userData.user;
      this.chatService.getUserList()
        .then((data: any) => {
          if (data.success == true) {
            let users = data.users;
            for (let i = 0; i < users.length; i++) {
              if (users[i].fullName == this.userInfo.fullName) {
                users.splice(i, 1);
                break;
              }
            }
            this.userList = users.sort(this.compareByfullName);
            this.chatWith = this.userList[0].fullName;
            this.initChatFeature();
          }
        });

    }
  }
  ngOnDestroy() {
    if (this.authenticationService.userToken() != undefined) {
      if (this.isStartChat) {
        this.receiveActiveObs.unsubscribe();
        this.receiveMessageObs.unsubscribe();
      }
    }
  }

  initChatFeature() {
    this.chatService.connect(this.userInfo.fullName);
    this.getMessages(this.chatWith);
    this.connectToChat();
    this.joinned = true;
    this.isStartChat = true;
  }
  ngAfterViewChecked() {

  }
  ngAfterViewInit() {
  }

  chatDataModelChange() {
    this.mScrollbarService.scrollTo(this.myScrollContainer.nativeElement, 'bottom', { scrollInertia: 500, moveDragger: true });
  }

  sendMessage() {
    let newMessage: Message = {
      created: new Date(),
      from: this.userInfo.fullName,
      text: this.msgData.message,
      conversationId: this.conversationId,
      inChatRoom: false
    };

    this.chatService.sendMessage(newMessage, this.chatWith);
    newMessage.mine = true;
    this.noMsg = false;
    this.messageList.push(newMessage);
    this.chatDataModelChange();
    this.msgSound();
    this.msgData.message = "";
  }

  logout() {
    var date = new Date();
    var userChatRoom = this.authenticationService.getKeyValLocal("userChatRoom");
    this.socket.emit('save-message', { room: userChatRoom, nickname: this.userInfo.fullName, message: 'Left this room', updated_at: date, socketId: this.newCounsellorSelected });
    localStorage.removeKeyLocal("userChatRoom");
    this.socket.emit('logout');
    this.joinned = false;
  }
  getMessages(name: string): void {
    this.chatService.getConversation(this.userInfo.fullName, name)
      .then(data => {
        if (data.success == true) {
          this.conversationId = data.conversation._id || data.conversation._doc._id;
          let messages = data.conversation.messages || null;
          this.mScrollbarService.initScrollbar(this.myScrollContainer.nativeElement, { axis: 'y', theme: 'dark' });
          if (messages && messages.length > 0) {
            for (let message of messages) {
              this.checkMine(message);
            }
            this.noMsg = false;
            this.messageList = messages;
            this.chatDataModelChange();
          } else {
            this.noMsg = true;
            this.messageList = [];
          }
        } else {
          this.noMsg = true;
          this.messageList = [];
        }
      });
  }
  checkMine(message: Message): void {
    if (message.from == this.userInfo.fullName) {
      message.mine = true;
    }
  }
  getUserStatus(): void {
    this.chatService.getUserList()
      .then((data: any) => {
        if (data.success == true) {
          let users = data.users;
          for (let i = 0; i < users.length; i++) {
            if (users[i].fullName == this.userInfo.fullName) {
              users.splice(i, 1);
              break;
            }
          }
          this.userList = users.sort(this.compareByfullName);
          this.receiveActiveObs = this.chatService.receiveActiveList()
            .subscribe(users => {
              this.chatService.getUserList()
                .then((data: any) => {
                  if (data.success == true) {
                    let users = data.users;
                    for (let i = 0; i < users.length; i++) {
                      if (users[i].fullName == this.userInfo.fullName) {
                        users.splice(i, 1);
                        break;
                      }
                    }
                    this.userList = users.sort(this.compareByfullName);
                  }
                });
              for (let user of this.userList) {
                let flag = 0;
                for (let liveUser of users) {
                  if (liveUser.fullName == user.fullName) {
                    user.online = true;
                    flag = 1;
                    break;
                  }
                }
                if (flag == 0) {
                  user.online = false;
                }
              }
              this.currentOnline = this.checkOnline(this.chatWith);
            });

          this.chatService.getActiveList();
        } else {
          this.router.navigate(["/dashboard"]);
        }
      });
  }

  checkOnline(name: string): boolean {
    for (let user of this.userList) {
      if (user.fullName == name) {
        return user.online;
      }
    }
  }

  onUsersClick(): void {
    this.showActive = !this.showActive;
  }

  onNewConv(username: string) {
    if (this.chatWith != username) {
      this.chatWith = username
      this.getMessages(username);
      this.initChatFeature();
    } else {
      this.getMessages(username);
    }
    this.currentOnline = this.checkOnline(username);
    this.showActive = false;
  }

  notifSound(): void {
    let sound: any = this.el.nativeElement.querySelector('#notifSound');
    sound.play();
  }
  msgSound(): void {
    let sound: any = this.el.nativeElement.querySelector('#msgSound');
    sound.load();
    sound.play();
  }
  initReceivers(): void {
    this.getUserStatus();
    this.receiveMessageObs = this.chatService.receiveMessage()
      .subscribe(message => {
        this.checkMine(message);
        if (message.conversationId == this.conversationId) {
          this.noMsg = false;
          this.messageList.push(message);
          this.chatDataModelChange();
          this.msgSound();
        } else if (message.mine != true) {
          if (this.notification.timeout) { clearTimeout(this.notification.timeout) };
          this.notification = {
            from: message.from,
            inChatRoom: message.inChatRoom,
            text: message.text,
            timeout: setTimeout(() => { this.notify = false }, 4000)
          };
          this.notify = true;
          this.notifSound();
        }
      });
  }
  connectToChat(): void {
    let connected = this.chatService.isConnected();
    if (connected == true) {
      this.initReceivers();
    } else {
      this.chatService.connect(this.userInfo.fullName, () => {
        this.initReceivers();
      });
    }
  }
  compareByfullName(a, b): number {
    if (a.fullName < b.fullName)
      return -1;
    if (a.fullName > b.fullName)
      return 1;
    return 0;
  }

}