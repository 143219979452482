import { Component, OnInit, OnChanges } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';

@Component({
  selector: 'app-view-edit-advertisement',
  templateUrl: './view-edit-advertisement.component.html',
  styleUrls: ['./view-edit-advertisement.component.scss']
})
export class ViewEditAdvertisementComponent implements OnInit, OnChanges {
  advertisementId: any;
  isEditable: boolean = false;
  title: string;
  rowData: any;
  rowDataAdvertisementShow: boolean = false;
  columnDefs: Array<any> = [];
  advServicesList: Array<any> = [];
  uploadFilesList: Array<any> = [];
  constructor(private router: Router, private route: ActivatedRoute, private httpService: HttpClient, private mScrollbarService: MalihuScrollbarService) {
    this.columnDefs = [
      { headerName: 'Advertisements ID', field: 'advertisement_Id', edit: false, type: 'input' },
      { headerName: 'Advertisement Type', field: 'advertisementIsType', edit: false, type: 'select' },
      { headerName: 'Advertisement Heading', field: 'advertisementHeading', edit: true, type: 'input' },
      { headerName: 'Advertisement Second Heading', field: 'advertisementSecondHeading', edit: true, type: 'input' },
      { headerName: 'Advertisement Services', field: 'advertisementServices', edit: true, type: 'input' }
    ];
    this.advServicesList = [
      { subHeaderName: 'Service ID', subField: 'services_ID', edit: false, type: 'input' },
      { subHeaderName: 'Service Name', subField: 'serviceName', edit: true, type: 'input' },
      { subHeaderName: 'Service Description', subField: 'serviceDescription', edit: true, type: 'ckeditor' }
    ];
    this.route.params.subscribe(params => {
      this.advertisementId = params['id'];
      this.isEditable = params['isEditable'] == 'true' ? true : false;
    });

  }
  ngOnChanges(){

  }

  ngOnInit() {
    if (this.isEditable) {
      this.title = 'Edit Advertisement';
    }
    else {
      this.title = 'View Advertisement';
    }
    this.listDataView();
  }

  listDataView() {
    this.httpService.get('/api/getAdvertisements/' + this.advertisementId).subscribe((res: any) => {
      this.rowData = res[0];
      if (this.rowData.advertisementIsType == 'video') { 
        this.advServicesList.push({ subHeaderName: 'Service Video Url', subField: 'serviceVideoSrc',edit: true, type:'input' });
      }
      this.rowDataAdvertisementShow = true;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }
  addNewAdvertisementService() {
    let lengthService = this.rowData.advertisementListServices.length;
    let innerObj = {};
    this.advServicesList.forEach((elm) => {
      innerObj[elm.subField] = '';
      if (elm.subField == 'services_ID') {
        innerObj[elm.subField] = 'SE' + (parseInt(lengthService) + 1);
      }
    });
    this.rowData.advertisementListServices.push(innerObj);
  }
  removeNewAdvertisementService(event, removeIndex) {
    this.rowData.advertisementListServices.splice(removeIndex, 1);
  }
  updateAdvertisementData() {
    let obj = this.rowData;
this.httpService.post('/api/setAdvertisements/' + this.advertisementId, obj).subscribe((res: any) => {
  this.router.navigate(['/dashboard/advertisements']);
}, (err: HttpErrorResponse) => {
  console.log(err.message);
})
  }

}
