import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorage } from '../helpers/local-storage';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService){}
  canActivate(route: ActivatedRouteSnapshot):boolean{
      const userRole: string = this.authenticationService.userRole();
        const permission = route.data["permission"];
        
        let canActivate: boolean;

        if (!permission) throw new Error('Permissions is not setup!');
        if (!permission.only.length) throw new Error('Roles are not setup!');

        canActivate = permission.only.includes(userRole);

        if (!canActivate) this.router.navigate([permission.redirectTo]);

        return canActivate;
  }
}
