import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { HeaderService } from '../../header/header.service';
import { environment } from '../../../../environments/environment';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bannerlists',
  templateUrl: './bannerlists.component.html',
  styleUrls: ['./bannerlists.component.scss']
})
export class BannerlistsComponent implements OnInit {
  @ViewChild('bannerListScrollBody') bannerListScrollBodyElmnt;
  client_name: any = environment.client_name;
  columnDefs: Array<any> = [];
  rowData: Array<any>=[];

  constructor(private httpService: HttpClient,private modalService: NgbModal, private headerService: HeaderService, private mScrollbarService: MalihuScrollbarService) {
    this.columnDefs = [
      { headerName: 'Img ID', field: 'img_Id'},
      { headerName: 'Img Name', field: 'img_name'},
      { headerName: 'Img Source', field: 'img_src' },
      { headerName: 'Img Link', field: 'img_link' }
    ];
   }

  ngOnInit() {
    this.listDataView();

  }

  listDataView() {
    this.httpService.get('/api/getBannerList').subscribe((res: any) => {
      this.rowData = res;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  };
  
  openVerticallyCentered(content, id) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
  this.httpService.delete('/api/deleteBannerList/' + id).subscribe((res: any) => {
    this.rowData = [];
    this.listDataView();
   }, (err: HttpErrorResponse) => {
    console.log(err.message);
        });
      }, (reason) => {
        console.log(reason);
      });
}

ngAfterViewInit() {
  this.mScrollbarService.initScrollbar(this.bannerListScrollBodyElmnt.nativeElement, { axis: 'y', theme: 'dark', advanced: { autoExpandHorizontalScroll: true } });
}

}
