import { Component, OnInit } from '@angular/core';
import { LocalStorage } from '../../helpers/local-storage';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-bookhivtest',
  templateUrl: './bookhivtest.component.html',
  styleUrls: ['./bookhivtest.component.scss']
})
export class BookhivtestComponent implements OnInit {
  facility: any;
  userInfo: any;
  isLoggedInUser: boolean = false;
  appointmentSuccess: boolean = false;
  hivTestData: any = { 'fullName': '','gender':null,'age':'', 'mobileNumber': '', 'dateSlot': new Date(), 'timeSlot': '' }
  hivTestDateConfig: any = {};
  currentTestAppointmentNumber: any;
  constructor(private http: HttpClient, private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    if (this.authenticationService.userToken() != undefined) {
      this.isLoggedInUser = true;
    }
    this.facility = this.authenticationService.getKeyValLocal('appointmentFacilityData');
    this.hivTestData.dateSlot = this.getDateRequiredFormat(new Date());
    this.hivTestDateConfig.minDate = this.getDateRequiredFormat(new Date());
    this.hivTestDateConfig.maxDate = this.getDateRequiredFormat(new Date((new Date).setMonth((new Date).getMonth() + 6)));
    this.hivTestDateConfig.navigation = 'arrows';
    this.hivTestDateConfig.outsideDays = 'collapsed';
    this.hivTestDateConfig.showWeekNumbers = false;

    let str: any = '' + this.facility.hospital_name;
    if (this.facility.department.length > 0) {
      str += ', ' + this.facility.department;
    }
    if (this.facility.director.length > 0) {
      str += ', ' + this.facility.director;
    }
    if (this.facility.nodalName.length > 0) {
      str += ', ' + this.facility.nodalName;
    }

    if (this.facility.nodalDesignation.length > 0) {
      str += ', ' + this.facility.nodalDesignation;
    }

    if (this.facility.contact_no.length > 0) {
      str += ', ' + this.facility.contact_no;
    }

    this.facility.completeAddress = str + ',' + this.facility.address + '-' + this.facility.pincode + '.';
  }
  bookHivTest() {
    let customDate = this.hivTestData.dateSlot.day + '/' + this.hivTestData.dateSlot.month + '/' + this.hivTestData.dateSlot.year;
    this.hivTestData.dateSlot = new Date(this.hivTestData.dateSlot.year + '/' + this.hivTestData.dateSlot.month + '/' + this.hivTestData.dateSlot.day);
    this.hivTestData.location = this.facility.completeAddress;
    this.http.post('/api/bookHivTest', this.hivTestData).subscribe((resp: any) => {
      let customMessage = 'Hi, ' + resp.msg.fullName + ', -- CONFIRMED HIV Test Booking ID:' + resp.msg.appointmentNumber + ' for ' + customDate + ' at ' + resp.msg.timeSlot + ' with ' + resp.msg.location;
      if (resp.success) {
        let data: any = {
          mobile1: resp.msg.mobileNumber,
          message: customMessage
        };
        this.http.get('/api/sendsms', { params: data }).toPromise().then((res) => {
          this.currentTestAppointmentNumber = resp.msg.appointmentNumber;
          this.appointmentSuccess = true;
        }, (err) => {
          console.log(err);
        });
      }
    }, err => {
      console.log(err.error.msg);
    });
  }
  getDateRequiredFormat(date) {
    return { year: parseInt(date.getFullYear()), month: parseInt(date.getMonth() + 1), day: parseInt(date.getDate()) };
  }

}
