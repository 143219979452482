import { Component, OnInit } from '@angular/core';
import {Validators, FormBuilder, FormGroup} from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';

@Component({
  selector: 'app-view-edit-bannersection',
  templateUrl: './view-edit-bannersection.component.html',
  styleUrls: ['./view-edit-bannersection.component.scss']
})
export class ViewEditBannersectionComponent implements OnInit {
  bannersectiontId: any;
  isEditable: boolean= false;
  title: string;
  rowData: any;
  rowDataBannerSectionShow: boolean = false;
  columnDefs: Array<any> = [];
  bannersectionServicesList:Array<any> =[];
  constructor(private router: Router, private route: ActivatedRoute,private httpService: HttpClient,private mScrollbarService: MalihuScrollbarService) { 

    this.columnDefs = [
      { headerName: 'Bannersection ID', field: 'bannersection_Id', edit: false, type:'input'},
      { headerName: 'Bannersection Heading', field: 'bannersectionHeading',edit: true, type:'input' },
      { headerName: 'Bannersection SubHeading', field: 'bannersectionSubHeading',edit: true, type:'input' },
      { headerName: 'Bannersection Services', field: 'bannersectionServices',edit: true, type:'input' },
      { headerName: 'Bannersection Sec SubHeading', field: 'bannersectionSecSubHeading',edit: true, type:'input' },
      { headerName: 'Bannersection Button Text', field: 'bannersectionButtonText',edit: true, type:'input' },
      { headerName: 'Bannersection ButtonLink', field: 'bannersectionButtonLink',edit: true, type:'input' }
  ];
  this.bannersectionServicesList = [
    { subHeaderName: 'Service ID', subField: 'service_ID', edit: false, type: 'input' },
    { subHeaderName: 'Service Name', subField: 'serviceName', edit: true, type: 'input' }
  ];
  this.route.params.subscribe(params => {
  this.bannersectiontId = params['id'];
  this.isEditable = params['isEditable'] == 'true'? true: false;
  });
  }

  ngOnInit() {
    if (this.isEditable) {
      this.title = 'Edit Banner Section';
    }
    else {
      this.title = 'View Banner Section';
    }
    this.listDataView();
  }

  listDataView() {
    this.httpService.get('/api/getBannersections/' +this.bannersectiontId).subscribe((res: any) => {
      this.rowData = res[0];
      this.rowDataBannerSectionShow = true;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }

  addNewBannersectionService() {
    let lengthService = this.rowData.bannersectionlistOptions.length;
    let innerObj = {};
    this.bannersectionServicesList.forEach((elm) => {
      innerObj[elm.subField] = '';
      if (elm.subField == 'service_ID') {
        innerObj[elm.subField] = 'SE'+ (parseInt(lengthService) + 1);
      }
    });
    this.rowData.bannersectionlistOptions.push(innerObj);
  }
  removeNewBannersectionService(event, removeIndex) {
    this.rowData.bannersectionlistOptions.splice(removeIndex , 1);
  }
  updateBannersectionData() {
    let obj = this.rowData;
    this.httpService.post('/api/setBannersections/'+ this.bannersectiontId, obj).subscribe((res: any) => {
      this.router.navigate(['/dashboard/bannersections']);
     }, (err: HttpErrorResponse) => {
      console.log(err.message);
    })
  }

}
