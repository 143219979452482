import { Component, OnInit, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  isLoggedInUser: boolean = false;
  isOrderDone: boolean = false;
  orderFreeConData: any = {};
  userInfo: any ={};
  constructor(private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private el: ElementRef,
    private router: Router, private http: HttpClient) { }

  ngOnInit() {
    if (this.authenticationService.userToken() != undefined) {
      this.isLoggedInUser = true;
      this.userInfo = this.authenticationService.getUserData().user;
      this.orderFreeConData.mobileNumber = this.userInfo.mobileNumber;
      this.orderFreeConData.fullName = this.userInfo.fullName;
    }
  }
  orderFreeCondom(event) {
    this.http.post('/api/OrderCondom', this.orderFreeConData).subscribe((resp: any) => {
          this.isOrderDone = true;
          setTimeout(() => {
            this.router.navigate(['home']);
          }, 4000);
        }, (err) => {
          this.isOrderDone = true;
          setTimeout(() => {
            this.router.navigate(['home']);
          }, 4000);
          console.log(err);
        });
  }
}
