import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { HeaderService } from '../../header/header.service';
import { environment } from '../../../../environments/environment';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-basics',
  templateUrl: './basics.component.html',
  styleUrls: ['./basics.component.scss']
})
export class BasicsComponent implements OnInit {
  @ViewChild('basicScrollBody') basicScrollBodyElmnt;
  client_name: any = environment.client_name;
  columnDefs: Array<any> = [];
  rowData: Array<any>=[];

  constructor(private httpService: HttpClient, private modalService: NgbModal,private headerService: HeaderService, private mScrollbarService: MalihuScrollbarService) {

    this.columnDefs = [
      { headerName: 'Basic HIV ID', field: 'basichiv_Id'},
      { headerName: 'Basic HIV Icon', field: 'basichivIcon'},
      { headerName: 'Basic HIV Heading', field: 'basichivHeading'}
    ];
   }

  ngOnInit() {
    this.listDataView();
  }
  listDataView() {
    this.httpService.get('/api/getBasichiv').subscribe((res: any) => {
      this.rowData = res;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  };
  
  openVerticallyCentered(content, id) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.httpService.delete('/api/deleteBasichiv/' + id).subscribe((res: any) => {
        this.rowData = [];
        this.listDataView();
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      });
    }, (reason) => {
      console.log(reason);
    });
}

ngAfterViewInit() {
  this.mScrollbarService.initScrollbar(this.basicScrollBodyElmnt.nativeElement, { axis: 'y', theme: 'dark', advanced: { autoExpandHorizontalScroll: true } });
}

}
