// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  client_name:"deepshika",
  firebase: {
    apiKey: "AIzaSyA2x8sO_UXprqZ7Whxe1kR-wtMt4kFlsTM",
    authDomain: "virtual-29c40.firebaseapp.com",
    databaseURL: "https://virtual-29c40.firebaseio.com",
    projectId: "virtual-29c40",
    storageBucket: "virtual-29c40.appspot.com",
    messagingSenderId: "315365262737"
  }
};
