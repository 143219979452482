import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { HeaderService } from '../../header/header.service';
import { environment } from '../../../../environments/environment';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bannersections',
  templateUrl: './bannersections.component.html',
  styleUrls: ['./bannersections.component.scss']
})
export class BannersectionsComponent implements OnInit {
  @ViewChild('bannersectionScrollBody') bannersectionScrollBodyElmnt;
  client_name: any = environment.client_name;
  columnDefs: Array<any> = [];
  rowData: Array<any> = [];

  constructor(private httpService: HttpClient, private modalService: NgbModal, private headerService: HeaderService, private mScrollbarService: MalihuScrollbarService) {
  this.columnDefs = [
    { headerName: 'Bannersection ID', field: 'bannersection_Id' },
    { headerName: 'BannersectionHeading', field: 'bannersectionHeading' },
    { headerName: 'Bannersection Sub Heading', field: 'bannersectionSubHeading' },
    { headerName: 'Bannersection Services', field: 'bannersectionServices' }
  ];
  };

  ngOnInit() {
    this.listDataView();
  }
  listDataView() {
    this.httpService.get('/api/getBannersections').subscribe((res: any) => {
      this.rowData = res;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  };

  openVerticallyCentered(content, id) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.httpService.delete('/api/deleteBannersections/' + id).subscribe((res: any) => {
        this.rowData = [];
        this.listDataView();
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      });
    }, (reason) => {
      console.log(reason);
    });
  }
}