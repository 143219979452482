import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClientModule, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from "../../services/authentication.service";
import { LocalStorage } from '../../helpers/local-storage';
import { HeaderService } from '../../components/header/header.service';

@Component({
  selector: 'app-topheader',
  templateUrl: './topheader.component.html',
  styleUrls: ['./topheader.component.scss']
})
export class TopheaderComponent implements OnInit {
  sectionInfo: any;

  constructor(private http: HttpClient,private router: Router,private authenticationService: AuthenticationService, private headerService: HeaderService,) { }
  isCounsellor: boolean = false;
  visible: boolean = true;
  ngOnInit() {
    this.http.get('/api/getappinfo').subscribe((res: any) => {
      this.sectionInfo = res[0];
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }
  signOut() {
    LocalStorage.clearStorage();
    this.authenticationService.isAuthenticated(false);
    this.authenticationService.logout();
    this.isCounsellor= false;
    this.headerService.show();
    this.router.navigate(['home']);

  }
}
