import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../header/header.service';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-hiv',
  templateUrl: './hiv.component.html',
  styleUrls: ['./hiv.component.scss']
})
export class HivComponent implements OnInit {

  constructor(private httpService: HttpClient,private headerService: HeaderService) { }

  columnDefs = [
    {headerName: 'Id', field: '_id' },
    {headerName: 'appointmentNumber', field: 'appointmentNumber'},
    {headerName: 'booked_date', field: 'booked_date'},
    {headerName: 'fullName', field: 'fullName' },
    {headerName: 'gender', field: 'gender' },
    {headerName: 'age', field: 'age'},
    {headerName: 'location', field: 'location' },
    {headerName: 'mobileNumber', field: 'mobileNumber' },
    {headerName: 'timeSlot', field: 'timeSlot' }

  ]
  rowData: any;
  
  ngOnInit() {
    this.headerService.hide();
    this.listDataView();
  }

  listDataView(){
    this.httpService.get('/api/bookhivtest').subscribe((res: any) => {
      this.rowData = res;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }
}
