import { Component, OnInit, Inject } from '@angular/core';
import { HttpClientModule, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { HeaderService } from './header.service';
import { AuthenticationService } from "../../services/authentication.service";
import { LocalStorage } from '../../helpers/local-storage';
import { DOCUMENT } from '@angular/platform-browser';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isLoggedInUser: boolean = false;
  isCounsellor: boolean = false;
  visible: boolean = true;
  sectionInfo:any = [];
  constructor(@Inject(DOCUMENT) private document: any,private http: HttpClient,
    private router: Router,
    private modalService: NgbModal,
    private headerService: HeaderService,
    private authenticationService: AuthenticationService) {
  }

  ngOnInit() {

    this.http.get('/api/getappinfo').subscribe((res: any) => {
      this.sectionInfo = res[0];
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });

    
    
    this.authenticationService.isLoggendInUserChange.subscribe((val) => {
      this.isLoggedInUser = val;
    });
    if (this.authenticationService.userToken() != undefined) {
      this.isLoggedInUser = true;
      let userInfoRole = this.authenticationService.userRole();
      if (userInfoRole == "Counsellor") {
        this.isCounsellor = true;
      }
      else {
        this.isCounsellor= false;
      }
    }
    this.headerService.isVisibleChange.subscribe((val) => {
      this.visible = val;
    });

  }
  signOut() {
    LocalStorage.clearStorage();
    this.authenticationService.isAuthenticated(false);
    this.authenticationService.logout();
    this.isCounsellor= false;
    this.router.navigate(['home']);
  }
}
