import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-add-services',
  templateUrl: './add-services.component.html',
  styleUrls: ['./add-services.component.scss']
})
export class AddServicesComponent implements OnInit {
  rowData: any={};
  rowDataServicesShow: boolean = false;
  columnDefs: Array<any> = [];
  client_name: any = environment.client_name;

  constructor(private router: Router, private route: ActivatedRoute,private httpService: HttpClient,private mScrollbarService: MalihuScrollbarService) { 

    this.columnDefs = [
      { headerName: 'Service ID', field: 'services_Id', edit: false, type:'input'},
      { headerName: 'Service Name', field: 'services_name',edit: true, type:'input'},
      { headerName: 'Service Icon', field: 'services_icon',edit: true, type:'input'},
      { headerName: 'Service Agent Name', field: 'agent_name',edit: true, type:'input' },
      ];
  }

  ngOnInit() {
    this.listDataView();
  }
  listDataView() {

    this.httpService.get('/api/serviceList').subscribe((res: any) => {
        // this.rowData = res;
      if (res.length == 0) {
        this.rowData.services_Id = 'SG01';
      }
      else {
        let length = res.length - 1;
        this.rowData.services_Id = 'SG0' + (parseInt((res[length].services_Id).replace('SG0', '')) + 1);
      }
       this.rowDataServicesShow = true;
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }


  addNewServicesData(event) {
    let obj = this.rowData;
    this.httpService.post('/api/saveServices', obj).subscribe((res: any) => { 
      this.router.navigate(['/dashboard/services']);
     }, (err: HttpErrorResponse) => {
      console.log(err.message);
    })
  }

}
