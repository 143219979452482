import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { HeaderService } from '../../header/header.service';
import { environment } from '../../../../environments/environment';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact-admin',
  templateUrl: './contact-admin.component.html',
  styleUrls: ['./contact-admin.component.scss']
})
export class ContactAdminComponent implements OnInit {

  @ViewChild('basicScrollBody') basicScrollBodyElmnt;
  client_name: any = environment.client_name;
  columnDefs: Array<any> = [];
  rowData: Array<any>=[];

  constructor(private httpService: HttpClient, private modalService: NgbModal,private headerService: HeaderService, private mScrollbarService: MalihuScrollbarService) {

    this.columnDefs = [
      { headerName: 'contact ID', field: 'contact_Id'},
      { headerName: 'contact Icon', field: 'contactIcon'},
      { headerName: 'contact Heading', field: 'contactHeading'}
    ];
   }

  ngOnInit() {
    this.listDataView();
  }
  listDataView() {
    this.httpService.get('/api/getcontacts').subscribe((res: any) => {
      this.rowData = res;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  };
  
  openVerticallyCentered(content, id) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.httpService.delete('/api/deletecontacts/' + id).subscribe((res: any) => {
        this.rowData = [];
        this.listDataView();
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      });
    }, (reason) => {
      console.log(reason);
    });
}

ngAfterViewInit() {
  this.mScrollbarService.initScrollbar(this.basicScrollBodyElmnt.nativeElement, { axis: 'y', theme: 'dark', advanced: { autoExpandHorizontalScroll: true } });
}

}
