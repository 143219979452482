import { Component, OnInit, ViewChild, Inject, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorage } from '../../helpers/local-storage';
import { AuthenticationService } from '../../services/authentication.service';
import { OwlCarousel } from 'ngx-owl-carousel';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/platform-browser';
import { RisksService } from '../../services/risks.service';
import { Option, Question, Quiz, QuizConfig } from '../../models/index';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./home.component.scss'],
  providers: [RisksService]
})
export class HomeComponent implements OnInit {
  isLoggedInUser: boolean = false;
  riskStatus:any;
  customOptions: any = {

    dots: false,
    navigation: false,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      600: {
        items: 2
      },
      1024: {
        items: 3
      },
      1600: {
        items: 4
      }
    },
    nav: true
  };

  newCustomOptions: any = {

    dots: false,
    navigation: false,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      600: {
        items: 1
      },
      740: {
        items: 2
      },
      1000: {
        items: 3
      },
      1600: {
        items: 3
      }
    },
    nav: false
  };
  closeResult: string;
  userSettings: any = {};
  programList: any;
  selectProgramId: any;
  bannerSections: any = [];
  bannerSlider: any = [];
  serviceList: any = [];
  advertismentSection: any = [];
  sectionInfo: any = [];
  riskAssesmentNumber: any;
  viewDetail: any;

  quizes: any[];
  noRisk = false;
  highRisk = false;
  quiz: Quiz = new Quiz(null);
  mode = 'quiz';
  quizName: string;
  config: QuizConfig = {
    'allowBack': true,
    'allowReview': true,
    'autoMove': false,  // if true, it will move to next question automatically when answered.
    'duration': 300,  // indicates the time (in secs) in which quiz needs to be completed. 0 means unlimited.
    'pageSize': 1,
    'requiredAll': false,  // indicates if you must answer all the questions before submitting.
    'richText': false,
    'shuffleQuestions': false,
    'shuffleOptions': false,
    'showClock': false,
    'showPager': true,
    'theme': 'none'
  };

  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  timer: any = null;
  startTime: Date;
  endTime: Date;
  duration = '';
  lastPage = false;

  @ViewChild('owlElement') owlElement: OwlCarousel;
  @ViewChild('owlElementImg') owlElementImg: OwlCarousel
  modalRef: any;
  constructor( @Inject(DOCUMENT) private document: any,
    private httpService: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private quizService: RisksService) { }

  ngOnInit() {
    this.quizes = this.quizService.getAll();
    this.quizName = this.quizes[0].id;
    this.loadQuiz(this.quizName);

    this.authenticationService.isLoggendInUserChange.subscribe((val) => {
      this.isLoggedInUser = val;
    });
    if (this.authenticationService.userToken() != undefined) {
      this.isLoggedInUser = true;
    }

    this.httpService.get('/api/getappinfo').subscribe((res: any) => {
      this.sectionInfo = res[0];
    }, (err: HttpErrorResponse) => {
      //console.log(err.message);
    });

    this.httpService.get('/api/getRiskSurvey').subscribe((res: any) => {
      this.riskAssesmentNumber = res.length + 1;
    }, (err: HttpErrorResponse) => {
      
    });


    this.userSettings = {
      "showSearchButton": false,
      "showRecentSearch": false,
      "geoCountryRestriction": ['in'],
      "showCurrentLocation": true,
      "inputString": "Delhi, India"
    }

    this.httpService.get('/api/programList').subscribe((data: any) => {
      this.programList = data;
      this.authenticationService.setKeyValLocal('programList', this.programList);
    }, (err: HttpErrorResponse) => {
      //console.log(err.message);
    });

    this.httpService.get('/api/getAdvertisements').subscribe((res: any) => {
      this.advertismentSection = res;
    }, (err: HttpErrorResponse) => {
      //console.log(err.message);
    });

    this.httpService.get('/api/getBannersections').subscribe((res: any) => {
      this.bannerSections = res;
    }, (err: HttpErrorResponse) => {
      //console.log(err.message);
    });

    this.httpService.get('/api/serviceList').subscribe((res: any) => {
      this.serviceList = res;
    }, (err: HttpErrorResponse) => {
      //console.log(err.message);
    });


    this.httpService.get('/api/getBannerList').subscribe((res: any) => {
      this.bannerSlider = res;
    }, (err: HttpErrorResponse) => {
      //console.log(err.message);
    });

  }
  loadQuiz(quizName: string) {
    this.quizService.get(quizName).subscribe(res => {
      this.quiz = new Quiz(res);
      this.pager.count = this.quiz.questions.length;
    });
    this.mode = 'quiz';
  }
  ngAfterViewInit() {
    this.autoPlayOwl();
  }
  autoPlayOwl() {
    // this.owlElement.trigger('play.owl.autoplay', [5000, 200]);
    // this.owlElementImg.trigger('play.owl.autoplay', [5000, 200]);
    // //duration 200ms
  }
  autoCompleteCallback1(selectedData: any) {
    this.authenticationService.setKeyValLocal('selectedLocation', selectedData.data);
  }
  getResultList(data) {
    this.authenticationService.setKeyValLocal('selectedProgramId', this.selectProgramId);
    this.router.navigate(['listing-view']);
  }
  openVerticallyCentered(content, id, servId, type) {
    this.modalService.open(content, { centered: true });

    if (type == "detail") {
      this.advertismentSection.forEach(element => {
        if (element.advertisement_Id == id) {
          element.advertisementListServices.forEach(data => {
            if (data.services_ID == servId) {
              this.viewDetail = data;
            }
          });
        }
      });
    }
  }
  parseTime(totalSeconds: number) {
    let mins: string | number = Math.floor(totalSeconds / 60);
    let secs: string | number = Math.round(totalSeconds % 60);
    mins = (mins < 10 ? '0' : '') + mins;
    secs = (secs < 10 ? '0' : '') + secs;
    return `${mins}:${secs}`;
  }

  get filteredQuestions() {
    return (this.quiz.questions) ?
      this.quiz.questions.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  onSelect(question: Question, option: Option) {
    if (question.questionTypeId === 1) {
      question.options.forEach((x) => { if (x.id !== option.id) x.selected = false; });
    }

    if (this.config.autoMove) {
      this.goTo(this.pager.index + 1);
    }
  }

  goTo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = 'quiz';
      this.lastPage = false;
    }
    if (index === 9) {
      this.lastPage = true;
    }
  }

  isAnswered(question: Question) {
    return question.options.find(x => x.selected) ? 'Answered' : 'Not Answered';
  };

  isCorrect(question: Question) {
    return question.options.every(x => 
      x.selected === x.isAnswer) ? 'correct' : 'wrong';
  };

  onSubmit() {
    let answers = [];
    let finalresult = [];
    this.quiz.questions.forEach(x => {
      let result = x.options.filter(item => x['selected'] == true && item.isAnswer == true);
      answers.push({ 'quizId': this.quiz.id, 'questionId': x.id, 'result': result.length > 0});
    });
    // Post your data to the server here. answers contains the questionId and the users' answer.
    this.mode = 'result';
    this.answerCalculate(answers);
    finalresult.push({'riskSurveyNumber': this.riskAssesmentNumber , 'riskSurveyResult': answers,'riskStatus': this.riskStatus});
    this.httpService.post('/api/saveRiskSurvey', finalresult[0]).subscribe((res: any) => {
    
    }, (err: HttpErrorResponse) => {
      //console.log(err.message);
    });
    this.riskAssesmentNumber = this.riskAssesmentNumber + 1;
  }
  answerCalculate(data){
    let risk = data.filter(element => {
      if (element.questionId !== '1010' || element.questionId !== '1011' || element.questionId !== '1012' ){
       return element.result == true;
      }
      else{
        return false;
      }
    });
    if(risk.length >= 7 ){
      this.noRisk = false;
      this.highRisk = true;
      this.riskStatus = 'High Risk';
    }
    else{
      this.noRisk = true;
      this.highRisk = false;
      this.riskStatus = 'Low Risk';
    }
  }
  openScrollableContent(longContent) {
    this.modalRef = this.modalService.open(longContent, { centered: true, size: 'lg' });
  }
  closePoup() {
    this.mode = 'quiz';
    this.pager.index = 0;
    this.lastPage = false;
    this.quiz.questions.forEach(question => {
      delete question['selected'];
    });
    this.modalRef.dismiss();
  }
}
