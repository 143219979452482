import { Component, OnInit } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HeaderService } from '../header/header.service';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { Util } from '../../helpers/util';
  import { from } from 'rxjs';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private headerService: HeaderService, private util:Util ) { }

  ngOnInit() {
    this.headerService.hide();
  }

}
