import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../header/header.service';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Util } from '../../../helpers/util';
import {ExcelService} from '../../../services/excel.service';

@Component({
  selector: 'app-risk-assesment',
  templateUrl: './risk-assesment.component.html',
  styleUrls: ['./risk-assesment.component.scss']
})
export class RiskAssesmentComponent implements OnInit {

  resultFoundGet: any;
  private gridApi;
  private gridColumnApi;

  constructor(private excelService:ExcelService, private httpService: HttpClient, private headerService: HeaderService, private util: Util) { }

  columnDefs = [
    { headerName: 'Count Users', field: 'riskSurveyNumber', cellClass: "cell-wrap-text", width: 130 },
    { headerName: 'Questions Result', field: 'answers', cellClass: "cell-wrap-text" },
    { headerName: 'Risk Status', field: 'riskStatus', cellClass: "cell-wrap-text" },
    { headerName: 'Created Date', field: 'createdDate', cellClass: "cell-wrap-text" }
  ]
  rowData: any;
  ngOnInit() {
    this.headerService.hide();
    this.listDataView();
  }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.rowData, 'riskassessment');
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    setTimeout(function () {
      params.api.resetRowHeights();
    }, 500);
  }
  onColumnResized(event) {
    if (event.finished) {
      this.gridApi.resetRowHeights();
    }
  }
  listDataView() {
    let resultGent = [];
    this.httpService.get('/api/getRiskSurvey').subscribe((res: any) => {
      this.rowData = [];
      res.forEach(element => {
        element.riskSurveyResult.forEach(ele => {
          resultGent.push(ele.result);
        });
        delete element['riskSurveyResult'];
        element['answers'] = resultGent.join(', ');
        this.rowData.push(element);
        resultGent = [];
      });
      
      this.resultFoundGet = res.length;
    }, (err: HttpErrorResponse) => {
      //console.log(err.message);
    });
  }
}
