
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { HeaderService } from '../../header/header.service';
import { environment } from '../../../../environments/environment';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-advertisements',
  templateUrl: './advertisements.component.html',
  styleUrls: ['./advertisements.component.scss']
})
export class AdvertisementsComponent implements OnInit {
  @ViewChild('advertisementScrollBody') advertisementScrollBodyElmnt;
  client_name: any = environment.client_name;
  columnDefs: Array<any> = [];
  rowData: Array<any> = [];
  constructor(private httpService: HttpClient, private headerService: HeaderService, private modalService: NgbModal, private mScrollbarService: MalihuScrollbarService) {
    this.columnDefs = [
      { headerName: 'Advertisements ID', field: 'advertisement_Id' },
      { headerName: 'Advertisement Type', field: 'advertisementIsType' },
      { headerName: 'Advertisement Heading', field: 'advertisementHeading' },
      { headerName: 'Advertisement Second Heading', field: 'advertisementSecondHeading' },
      { headerName: 'Advertisement Services', field: 'advertisementServices' }
    ];
  };

  ngOnInit() {
    this.listDataView();
  }

  listDataView() {
    this.httpService.get('/api/getAdvertisements').subscribe((res: any) => {
      this.rowData = res;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  };

  openVerticallyCentered(content, id) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.httpService.delete('/api/deleteAdvertisements/' + id).subscribe((res: any) => {
        this.rowData = [];
        this.listDataView();
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      });
    }, (reason) => {
      console.log(reason);
    });
  }

  ngAfterViewInit() {
    this.mScrollbarService.initScrollbar(this.advertisementScrollBodyElmnt.nativeElement, { axis: 'y', theme: 'dark', advanced: { autoExpandHorizontalScroll: true } });
  }
}
