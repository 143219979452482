import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-add-bannersection',
  templateUrl: './add-bannersection.component.html',
  styleUrls: ['./add-bannersection.component.scss']
})
export class AddBannersectionComponent implements OnInit {

  rowData: any={};
  rowDataBannerSectionShow: boolean = false;
  columnDefs: Array<any> = [];
  client_name: any = environment.client_name;
  bannersectionServicesList: Array<any>=[];

  constructor(private router: Router, private route: ActivatedRoute,private httpService: HttpClient,private mScrollbarService: MalihuScrollbarService) {
    this.columnDefs = [
      { headerName: 'Bannersection ID', field: 'bannersection_Id', edit: false, type:'input'},
      { headerName: 'Bannersection Heading', field: 'bannersectionHeading',edit: true, type:'input' },
      { headerName: 'Bannersection SubHeading', field: 'bannersectionSubHeading',edit: true, type:'input' },
      { headerName: 'Bannersection Services', field: 'bannersectionServices',edit: true, type:'input' },
      { headerName: 'Bannersection Sec SubHeading', field: 'bannersectionSecSubHeading',edit: true, type:'input' },
      { headerName: 'Bannersection Button Text', field: 'bannersectionButtonText',edit: true, type:'input' },
      { headerName: 'Bannersection ButtonLink', field: 'bannersectionButtonLink',edit: true, type:'input' }
  ];
  this.bannersectionServicesList = [
    { subHeaderName: 'Service ID', subField: 'service_ID', edit: false, type: 'input' },
    { subHeaderName: 'Service Name', subField: 'serviceName', edit: true, type: 'input' }

  ];
  this.columnDefs.forEach((elm) => {
    this.rowData[elm.field] = '';
  });
  this.rowData['bannersectionlistOptions'] = [];
  let innerObj = {};
  this.bannersectionServicesList.forEach((elm) => {
    innerObj[elm.subField] = '';
    if (elm.subField == 'service_ID') {
      innerObj[elm.subField] = 'SE1';
    }
  });
  this.rowData.bannersectionlistOptions.push(innerObj);
   }

  ngOnInit() {
    this.listDataView();
  }
  listDataView() {

    this.httpService.get('/api/getBannersections').subscribe((res: any) => {
        // this.rowData = res;
      if (res.length == 0) {
        this.rowData.bannersection_Id = 'SEC1';
      }
      else {
        let length = res.length - 1;
        this.rowData.bannersection_Id = 'SEC' + (parseInt((res[length].bannersection_Id).replace('SEC', '')) + 1);
      }
       this.rowDataBannerSectionShow = true;
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }
  addNewBannersectionService() {
    let lengthService = this.rowData.bannersectionlistOptions.length;
    let innerObj = {};
    this.bannersectionServicesList.forEach((elm) => {
      innerObj[elm.subField] = '';
      if (elm.subField == 'service_ID') {
        innerObj[elm.subField] = 'SE'+ (parseInt(lengthService) + 1);
      }
    });
    this.rowData.bannersectionlistOptions.push(innerObj);
  }
  removeNewBannersectionService(event, removeIndex) {
    this.rowData.bannersectionlistOptions.splice(removeIndex , 1);
  }
  addNewBannerSectionData(event) {
    let obj = this.rowData;
    this.httpService.post('/api/saveBannersections', obj).subscribe((res: any) => {
      this.router.navigate(['/dashboard/bannersections']);
     }, (err: HttpErrorResponse) => {
      console.log(err.message);
    })
  }

}
