import { Component, OnInit } from '@angular/core';
import { LocalStorage } from '../../helpers/local-storage';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from "@angular/router";
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-bookappointment',
  templateUrl: './bookappointment.component.html',
  styleUrls: ['./bookappointment.component.scss']
})
export class BookappointmentComponent implements OnInit {
  facility: any;
  serviceObject: any;
  userInfo: any;
  isLoggedInUser: boolean = false;
  appointmentSuccess: boolean = false;
  appointmentData: any = { 'fullName': '', 'mobileNumber': '', 'dateSlot': new Date(), 'timeSlot': '', 'services_name': '', 'services_Id': '' }
  appointmentDateConfig: any = {};
  currentAppointmentNumber: any;
  servicesOffered: Array<any> = [];
  WEEKDAYS: Array<any> = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  showDateBoxAvailability: boolean = false;
  showDateBoxNoAvailability: boolean = false;
  timeAvailbility: Array<any> = [];
  sectionInfo:any = [];
  constructor(private http: HttpClient, private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.http.get('/api/getappinfo').subscribe((res: any) => {
      this.sectionInfo = res[0];
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });

    if (this.authenticationService.userToken() != undefined) {
      this.http.get('/api/serviceList').subscribe((res: any) => {
        this.servicesOffered = res;
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      });
      this.isLoggedInUser = true;
      this.userInfo = this.authenticationService.getUserData().user;
      this.appointmentData.mobileNumber = this.userInfo.mobileNumber;
      this.appointmentData.fullName = this.userInfo.fullName;
    }
    this.serviceObject = this.authenticationService.getKeyValLocal('bookAppointmentData');
    this.facility = this.serviceObject.facility;
   // this.appointmentData.dateSlot = this.getDateRequiredFormat(new Date());
    this.appointmentData.services_Id = undefined;
    this.appointmentData.timeSlot = undefined;
    this.appointmentDateConfig.minDate = this.getDateRequiredFormat(new Date());
    this.appointmentDateConfig.maxDate = this.getDateRequiredFormat(new Date((new Date).setMonth((new Date).getMonth() + 6)));
    this.appointmentDateConfig.navigation = 'arrows';
    this.appointmentDateConfig.outsideDays = 'collapsed';
    this.appointmentDateConfig.showWeekNumbers = false;
    this.appointmentDateConfig.showWeekdays = true;

    let str: any = '' + this.facility.hospital_name;
    if (this.facility.department.length > 0) {
      str += ', ' + this.facility.department;
    }
    if (this.facility.director.length > 0) {
      str += ', ' + this.facility.director;
    }
    if (this.facility.nodalName.length > 0) {
      str += ', ' + this.facility.nodalName;
    }

    if (this.facility.nodalDesignation.length > 0) {
      str += ', ' + this.facility.nodalDesignation;
    }

    if (this.facility.contact_no.length > 0) {
      str += ', ' + this.facility.contact_no;
    }

    this.facility.completeAddress = str + ',' + this.facility.address + '-' + this.facility.pincode + '.';
  }
  onDateChange(e) {
    let customDateWeek = e.month + '/' + e.day + '/' + e.year;
    let selectedWeekNumber = (new Date(customDateWeek)).getDay();
    let selectedWeekName = this.WEEKDAYS[selectedWeekNumber];
    this.facility.appointmentTiming.forEach(timeVal => {
      if (timeVal.days == selectedWeekName) {
        this.timeAvailbility = (timeVal.time == null) ? []: timeVal.time;
      }
    });
    if (this.timeAvailbility.length == 0) {
      this.showDateBoxNoAvailability = true;
      this.appointmentData.timeSlot = undefined;
      this.showDateBoxAvailability = false;
    }
    else {
      this.appointmentData.timeSlot = undefined;
      this.showDateBoxNoAvailability = false;
      this.showDateBoxAvailability = true;
    }
  }
  bookAppointment() {
    this.servicesOffered.forEach((service) => {
      if (service.services_Id == this.appointmentData.services_Id) {
        this.appointmentData.services_name = service.services_name;
      }
    });
    let customDate = this.appointmentData.dateSlot.day + '/' + this.appointmentData.dateSlot.month + '/' + this.appointmentData.dateSlot.year;
    this.appointmentData.dateSlot = new Date(this.appointmentData.dateSlot.year + '/' + this.appointmentData.dateSlot.month + '/' + this.appointmentData.dateSlot.day);
    this.appointmentData.location = this.facility.completeAddress;
    this.http.post('/api/bookAppointment', this.appointmentData).subscribe((resp: any) => {
      let customMessage = 'Hi, ' + resp.msg.fullName + ', -- CONFIRMED Appointment ID:' + resp.msg.appointmentNumber + ' for service name:' + resp.msg.services_name + ' on ' + customDate + ' at ' + resp.msg.timeSlot + ' with ' + resp.msg.location;
      if (resp.success) {
        let data: any = {
          mobile1: resp.msg.mobileNumber,
          mobile2: this.sectionInfo.appinfo_counsellorno,
          message: customMessage
        };
        this.http.get('/api/sendsms', { params: data }).toPromise().then((res) => {
          this.currentAppointmentNumber = resp.msg.appointmentNumber;
          this.appointmentSuccess = true;
        }, (err) => {
          console.log(err);
        });
      }
    }, err => {
      console.log(err.error.msg);
    });
  }
  getDateRequiredFormat(date) {
    return { year: parseInt(date.getFullYear()), month: parseInt(date.getMonth() + 1), day: parseInt(date.getDate()) };
  }

}
