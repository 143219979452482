import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import { LocalStorage } from '../../helpers/local-storage';
import { HttpClientModule, HttpClient, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  sectionInfo:any = [];
  constructor( private authenticationService: AuthenticationService,private http:HttpClient) { }

  ngOnInit() {
    
    this.http.get('/api/getappinfo').subscribe((res: any) => {
      this.sectionInfo = res[0];
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }

}
