import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { Util } from '../../../helpers/util';
import { DOCUMENT } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-app-info',
  templateUrl: './app-info.component.html',
  styleUrls: ['./app-info.component.scss']
})
export class AppInfoComponent implements OnInit {
  public uploader1: FileUploader = new FileUploader({ url: '', allowedMimeType: ['image/jpeg', 'image/jpg', 'image/tiff', 'image/gif', 'image/bmp', 'image/png', 'image/bpg', 'image/svg', 'image/svg+xml'] });
  public uploader2: FileUploader = new FileUploader({ url: '', allowedMimeType: ['image/jpeg', 'image/jpg', 'image/tiff', 'image/gif', 'image/bmp', 'image/png', 'image/bpg', 'image/svg', 'image/svg+xml'] });
  isEditable: boolean = false;
  rowData: any;
  rowDataFacilityShow: boolean = false;
  columnDefs: Array<any> = [];
  upload1BtnAfterMimeTypeCheck: boolean = false;
  upload1BtnAfterMimeTypeCheckError: boolean = false;
  upload1SuccessMegShow: boolean = false;
  upload2SuccessMegShow: boolean = false;
  upload2BtnAfterMimeTypeCheck: boolean = false;
  upload2BtnAfterMimeTypeCheckError: boolean = false;
  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private util: Util, private route: ActivatedRoute, private httpService: HttpClient, private mScrollbarService: MalihuScrollbarService) {
    this.uploader1.options.url = this.document.baseURI + 'api/upload';
    this.uploader2.options.url = this.document.baseURI + 'api/upload';
    let _self = this;
    this.uploader1.onAfterAddingFile = function (fileItem) {
      _self.upload1BtnAfterMimeTypeCheck = true;
      _self.upload1BtnAfterMimeTypeCheckError = false;
      return { fileItem: fileItem };
    };
    this.uploader1.onWhenAddingFileFailed = function (item, filter, options) {
      _self.upload1BtnAfterMimeTypeCheck = false;
      _self.upload1BtnAfterMimeTypeCheckError = true;
      return { item: item, filter: filter, options: options };
    };
    this.uploader1.onSuccessItem = function (item, response, status, headers) {
      let obj = {
        'response': response
      }
      _self.httpService.post('/api/setCompanyLogoPath/' + _self.rowData._id, obj).subscribe((res: any) => {
        _self.upload1SuccessMegShow = true;
        setTimeout(() => {
          _self.upload1SuccessMegShow = false;
        }, 2000);
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      })
      return { item: item, response: response, status: status, headers: headers };
    };
    this.uploader2.onAfterAddingFile = function (fileItem) {
      _self.upload2BtnAfterMimeTypeCheck = true;
      _self.upload2BtnAfterMimeTypeCheckError = false;
      return { fileItem: fileItem };
    };
    this.uploader2.onWhenAddingFileFailed = function (item, filter, options) {
      _self.upload2BtnAfterMimeTypeCheck = false;
      _self.upload2BtnAfterMimeTypeCheckError = true;
      return { item: item, filter: filter, options: options };
    };
    this.uploader2.onSuccessItem = function (item, response, status, headers) {
      let obj = {
        'response': response
      }
      _self.httpService.post('/api/setbgImageOnSearchPath/'+ _self.rowData._id, obj).subscribe((res: any) => {
        _self.upload2SuccessMegShow = true;
        setTimeout(() => {
          _self.upload2SuccessMegShow = false;
        }, 2000);
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      })
      return { item: item, response: response, status: status, headers: headers };
    };
    this.columnDefs = [
      { headerName: 'App ID', field: 'appinfo_Id', edit: false, type: 'input' },
      { headerName: 'Company Name', field: 'appinfo_companyname', edit: true, type: 'input' },
      { headerName: 'Company Tagline', field: 'appinfo_tagline', edit: true, type: 'textarea' },
      { headerName: 'Heading on Search Box', field: 'appinfo_searchead', edit: true, type: 'textarea' },
      { headerName: 'Sub-heading on Search Box', field: 'appinfo_searchsubhead', edit: true, type: 'textarea' },
      { headerName: 'Counsellor Number', field: 'appinfo_counsellorno', edit: true, type: 'input' },
      { headerName: 'Facebook Link', field: 'appinfo_fblink', edit: true, type: 'input' },
      { headerName: 'Twitter Link', field: 'appinfo_twlink', edit: true, type: 'input' },
      { headerName: 'Instagram Link', field: 'appinfo_instalink', edit: true, type: 'input' },
      { headerName: 'Youtube Link', field: 'appinfo_ytlink', edit: true, type: 'input' }
    ];
  }

  ngOnInit() {
    this.listDataView();
  }
  listDataView() {
    this.httpService.get('/api/getappinfo').subscribe((res: any) => {
      this.rowData = this.util.trimObjVal(res[0]);
      this.rowDataFacilityShow = true;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }
  updateAppInfoData() {
      delete this.rowData.appinfo_companylogo;
      delete this.rowData.appinfo_searchbg;
    let obj = this.rowData;
    this.httpService.post('/api/setappinfo/'+ this.rowData._id, obj).subscribe((res: any) => {
      this.isEditable = !this.isEditable;
      this.rowDataFacilityShow = false;
      this.listDataView();
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    })
  }

}