import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { Util } from '../../../../helpers/util';
import { JsonReader } from '../../../../helpers/json-reader';

@Component({
  selector: 'app-view-edit-facility',
  templateUrl: './view-edit-facility.component.html',
  styleUrls: ['./view-edit-facility.component.scss']
})
export class ViewEditFacilityComponent implements OnInit {
  facilityId: any;
  isEditable: boolean = false;
  title: string;
  getFacilityListData: any;
  rowData: any;
  rowDataFacilityShow: boolean = false;
  columnDefs: Array<any> = [];
  appointmentTimeList: Array<any> = [];
  time: any = { hour: 10, minute: 0 };
  weekTimeArr: any[];
  allProgramList: any;
  state_list: any;
  selectedStateDistrictList: Array<any>=[];
  constructor(private router: Router, private util: Util, private route: ActivatedRoute, private httpService: HttpClient, private mScrollbarService: MalihuScrollbarService) {
    this.columnDefs = [
      { headerName: 'Facilities ID', field: 'facilities_ID', edit: false, type: 'input' },
      { headerName: 'program ID', field: 'programID', edit: false, type: 'input' },
      { headerName: 'Facility Name', field: 'facility_name', edit: false, type: 'select' },
      { headerName: 'Hospital Name', field: 'hospital_name', edit: false, type: 'input' },
      { headerName: 'project ID', field: 'projectID', edit: false, type: 'input' },
      { headerName: 'Owner', field: 'isOwner', edit: false, type: 'input' },
      { headerName: 'Department', field: 'department', edit: true, type: 'input' },
      { headerName: 'Director', field: 'director', edit: true, type: 'input' },
      { headerName: 'Nodal Name', field: 'nodalName', edit: true, type: 'input' },
      { headerName: 'Nodal Designation', field: 'nodalDesignation', edit: true, type: 'input' },
      { headerName: 'Specialist', field: 'specialist', edit: true, type: 'input' },
      { headerName: 'Services', field: 'services', edit: true, type: 'input' },
      { headerName: 'Recommendation', field: 'recommendation', edit: true, type: 'input' },
      { headerName: 'Timing', field: 'time', edit: true, type: 'input' },
      { headerName: 'Costing', field: 'cost', edit: true, type: 'input' },
      { headerName: 'Feedback', field: 'feedback', edit: true, type: 'input' },
      { headerName: 'Address', field: 'address', edit: true, type: 'input' },
      { headerName: 'City', field: 'city', edit: true, type: 'input' },
      { headerName: 'Contact Number', field: 'contact_no', edit: true, type: 'input' },
      { headerName: 'State', field: 'state', edit: true, type: 'select' },
      { headerName: 'Pincode', field: 'pincode', edit: true, type: 'input' },
      { headerName: 'District', field: 'district', edit: true, type: 'select' },
      { headerName: 'Country', field: 'country', edit: false, type: 'input' },
      { headerName: 'Location', field: 'location', edit: true, type: 'input' },
      { headerName: 'Get Direction', field: 'getDirection', edit: true, type: 'input' }
    ];
    this.appointmentTimeList = [
      { subHeaderName: 'Week', subField: 'days', edit: true, type: 'selectWeek' },
      { subHeaderName: 'Time', subField: 'time', edit: true, type: 'timeSelect' },
    ];
    this.weekTimeArr = [
      {
        name: 'Sunday',
        subName: 'Sun',
        isChecked: false,
        timeArr: []
      },
      {
        name: 'Monday',
        subName: 'Mon',
        isChecked: false,
        timeArr: []
      },
      {
        name: 'Tuesday',
        subName: 'Tue',
        isChecked: false,
        timeArr: []
      },
      {
        name: 'Wednesday',
        subName: 'Wed',
        isChecked: false,
        timeArr: []
      },
      {
        name: 'Thursday',
        subName: 'Thu',
        isChecked: false,
        timeArr: []
      },
      {
        name: 'Friday',
        subName: 'Fri',
        isChecked: false,
        timeArr: []
      },
      {
        name: 'Saturday',
        subName: 'Sat',
        isChecked: false,
        timeArr: []
      }];

    this.route.params.subscribe(params => {
      this.facilityId = params['id'];
      this.isEditable = params['isEditable'] == 'true' ? true : false;
    });
  }

  ngOnInit() {
    if (this.isEditable) {
      this.title = 'Edit Facility';
    }
    else {
      this.title = 'View Facility';
    }
    this.state_list = JsonReader.getJson("state-list.json");
    this.httpService.get('/api/programList').subscribe((data: any) => {
      data.forEach(element => {
        element['disabled'] = true;
        if (element.program_Id == 'PG03') {
          element['disabled'] = false;
        }
      });
      this.allProgramList = data;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
    this.listDataView();
  }
  addNewTimeSlot(event, mainIndex, innerIndex) {
    let timeObj = { fromTime: { hour: 0, minute: 0 }, toTime: { hour: 0, minute: 0 } };
    this.weekTimeArr[mainIndex].timeArr.push(timeObj);
  }
  removeNewTimeSlot(event, mainIndex, innerIndex) {
    this.weekTimeArr[mainIndex].timeArr.splice(innerIndex,1);
  }
  listDataView() {
    this.httpService.get('/api/getFacilities/' + this.facilityId).subscribe((res: any) => {
      this.rowData = this.util.trimObjVal(res[0]);
      if (this.rowData.appointmentTiming.length > 0) {
        this.rowData.appointmentTiming.forEach(element => {
          this.weekTimeArr.forEach((weekObj, index) => {
            if (element.days == weekObj.subName) {
              if (element.time != null) {
                element.time.forEach(timeVal => {
                  let timeValTempArr = timeVal.split(' to ');
                  let fromPostfixArr = timeValTempArr[0].split(' ')[1];
                  let toPostfixArr = timeValTempArr[1].split(' ')[1];
                  let tempFromTimeHrMinArr = timeValTempArr[0].split(' ')[0].split(':');
                  let tempToTimeHrMinArr = timeValTempArr[1].split(' ')[0].split(':');
                  if (fromPostfixArr == 'pm') {
                    tempFromTimeHrMinArr[0] = parseInt(tempFromTimeHrMinArr[0]) + 12;
                  }
                  if (toPostfixArr == 'pm') {
                    tempToTimeHrMinArr[0] = parseInt(tempToTimeHrMinArr[0]) + 12;
                  }
                  let tempTimeObj = { fromTime: { hour: parseInt(tempFromTimeHrMinArr[0]), minute: parseInt(tempFromTimeHrMinArr[1]) }, toTime: { hour: parseInt(tempToTimeHrMinArr[0]), minute: parseInt(tempToTimeHrMinArr[1]) } };
                  this.weekTimeArr[index].timeArr.push(tempTimeObj);
                  this.weekTimeArr[index].isChecked = true;
                });
              }
              else {
                this.weekTimeArr[index].timeArr.push({ fromTime: { hour: 0, minute: 0 }, toTime: { hour: 0, minute: 0 } });
              }
            }
          });
          
        });
      }
      else {
        this.weekTimeArr.forEach((weekObj, index) => { 
          this.weekTimeArr[index].timeArr.push({ fromTime: { hour: 0, minute: 0 }, toTime: { hour: 0, minute: 0 } });
        });
      }
      this.getSelectedStateVal(event);
      this.rowDataFacilityShow = true;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }
  updateFacilityData() {
    let appointmentTiming = [];
    this.weekTimeArr.forEach((weekObj) => {
      let tempTime = null;
      if (weekObj.isChecked) {
        let objTempTime = [];
        weekObj.timeArr.forEach(timeVal => {
          let fromPostfix = 'am';
          let toPostfix = 'am';
          let fromHour = this.util.preZero(timeVal.fromTime.hour);
          let fromMinute = this.util.preZero(timeVal.fromTime.minute);
          let toHour = this.util.preZero(timeVal.toTime.hour);
          let toMinute = this.util.preZero(timeVal.toTime.minute);
          if (parseInt(timeVal.fromTime.hour) > 12) {
            fromPostfix = 'pm';
            fromHour = this.util.preZero((parseInt(timeVal.fromTime.hour) - 12));
          }
          if (parseInt(timeVal.toTime.hour) > 12) {
            toPostfix = 'pm';
            toHour = this.util.preZero((parseInt(timeVal.toTime.hour) - 12));
          }
          let tempFinalString = fromHour+':'+fromMinute+' '+fromPostfix + ' to '+ toHour+':'+toMinute+' '+toPostfix;
          objTempTime.push(tempFinalString);
        });
        tempTime = objTempTime;
      }
      let timeObj = {days:weekObj.subName, time:tempTime}
      appointmentTiming.push(timeObj);
    });
    this.rowData.appointmentTiming = appointmentTiming;
    let obj = this.rowData;
    this.httpService.post('/api/setFacilities/' + this.facilityId, obj).subscribe((res: any) => {
      this.router.navigate(['/dashboard/facility']);
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    })
  }
  getSelectedStateVal(event) {
    this.selectedStateDistrictList = [];
    this.state_list.states.forEach(stateVal => {
      if (stateVal.state == this.rowData.state) {
        this.selectedStateDistrictList = stateVal.districts;
      }
    });
  }
}
