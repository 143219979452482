import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { Observable ,  of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/messaging';
import { environment } from "../../../environments/environment";
import { WindowService } from "../../services/window.service";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  windowRef: any;
  signupData: any = { fullName: '', password: '', mobileNumber: '',age:'', associatedNGO:null,termsPolicy:false };
  message = '';
  hideSignupForm: boolean = false;
  showAlreadyExist: boolean = false;
  verificationCode: string = '';
  messageSent: boolean = false;
  completeMobileNumer: string;
  enableProceedBtn: boolean = false;
  smsSentFailed: boolean = false;
  smsSentFailedMsg: any;
  showPwdBox: boolean = true;
  smsOTPVerifyError: boolean = false;
  smsOTPVarifyErrorMsg: any;
  showAuthenticationValid: boolean = false;
  constructor(private http: HttpClient, private router: Router, private win: WindowService) {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
  }

  ngOnInit() {
    this.windowRef = this.win.windowRef;
    var _this = this;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-up-button', {
      'size': 'invisible',
      'callback': function (response) {
        _this.signup();
      }
    });
    this.windowRef.recaptchaVerifier.render();
  }
  signup() {
    this.http.post('/api/findMobileNumber', this.signupData).subscribe((resp:any) => {
      if (resp.success === false) {
        this.showAlreadyExist = false;
        this.sendOtpSms();
      }
      else {
        this.hideSignupForm = true;
        this.showAlreadyExist = true;
      }
    }, err => {
      console.log(err);
    });

   }

  sendOtpSms() {
    const appVerifier = this.windowRef.recaptchaVerifier;
    const phoneNumberString = '+91 ' + this.signupData.mobileNumber.toString();
    this.completeMobileNumer = phoneNumberString;
    firebase.auth().signInWithPhoneNumber(phoneNumberString, appVerifier)
      .then((result) => {
        this.hideSignupForm = true;
        this.messageSent = true;
        this.smsSentFailed = false;
        this.windowRef.confirmationResult = result;
      })
      .catch((err) => {
        this.hideSignupForm = false;
        this.smsSentFailed = true;
        this.smsSentFailedMsg = err.message;
      });
  }
  verifyLoginCode() {
    this.windowRef.confirmationResult
      .confirm(this.verificationCode)
      .then(result => {
        this.smsOTPVerifyError = false;
        this.smsOTPVarifyErrorMsg = '';
        this.http.post('/api/signup', this.signupData).subscribe(resp => {
          this.messageSent = false;
          this.showAuthenticationValid = true;
        }, err => {
          this.message = err.error.msg;
        });

      })
      .catch(error => {
        this.smsOTPVerifyError = true;
        this.smsOTPVarifyErrorMsg = error.message;
      });
  }
  resendOtplink() {
    this.verificationCode = '';
    this.smsOTPVerifyError = false;
    this.smsOTPVarifyErrorMsg = '';
    this.sendOtpSms();
  }
  reloadSignUp() {
    this.hideSignupForm = false;
    this.showAlreadyExist = false;
    this.windowRef.recaptchaVerifier.reset();
  }

}
