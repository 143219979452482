import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { environment } from '../../../../../environments/environment';
import { JsonReader } from '../../../../helpers/json-reader';
import { Util } from '../../../../helpers/util';

@Component({
  selector: 'app-add-new-facility',
  templateUrl: './add-new-facility.component.html',
  styleUrls: ['./add-new-facility.component.scss']
})
export class AddNewFacilityComponent implements OnInit {
  getFacilityListData: Array<any> = [];
  rowData: any = {};
  rowDataFacilityShow: boolean = false;
  columnDefs: Array<any> = [];
  client_name: any = environment.client_name;
  allProgramList: any;
  state_list: any;
  appointmentTimeList: Array<any> = [];
  time: any = { hour: 10, minute: 0 };
  weekTimeArr: any[];
  selectedStateDistrictList: Array<any> = [];
  constructor(private router: Router, private util: Util, private route: ActivatedRoute, private httpService: HttpClient, private mScrollbarService: MalihuScrollbarService) {
    this.columnDefs = [
      { headerName: 'Facilities ID', field: 'facilities_ID', edit: false, type: 'input' },
      { headerName: 'program ID', field: 'programID', edit: false, type: 'input' },
      { headerName: 'Facility Name', field: 'facility_name', edit: true, type: 'select' },
      { headerName: 'Hospital Name', field: 'hospital_name', edit: true, type: 'input' },
      { headerName: 'project ID', field: 'projectID', edit: true, type: 'input' },
      { headerName: 'Owner', field: 'isOwner', edit: false, type: 'input' },
      { headerName: 'Department', field: 'department', edit: true, type: 'input' },
      { headerName: 'Director', field: 'director', edit: true, type: 'input' },
      { headerName: 'Nodal Name', field: 'nodalName', edit: true, type: 'input' },
      { headerName: 'Nodal Designation', field: 'nodalDesignation', edit: true, type: 'input' },
      { headerName: 'Specialist', field: 'specialist', edit: true, type: 'input' },
      { headerName: 'Services', field: 'services', edit: true, type: 'input' },
      { headerName: 'Timing', field: 'time', edit: true, type: 'input' },
      { headerName: 'Costing', field: 'cost', edit: true, type: 'input' },
      { headerName: 'Address', field: 'address', edit: true, type: 'input' },
      { headerName: 'City', field: 'city', edit: true, type: 'input' },
      { headerName: 'Contact Number', field: 'contact_no', edit: true, type: 'input' },
      { headerName: 'State', field: 'state', edit: true, type: 'select' },
      { headerName: 'Pincode', field: 'pincode', edit: true, type: 'input' },
      { headerName: 'District', field: 'district', edit: true, type: 'select' },
      { headerName: 'Country', field: 'country', edit: false, type: 'input' },
      { headerName: 'Location', field: 'location', edit: true, type: 'input' },
      { headerName: 'Get Direction', field: 'getDirection', edit: true, type: 'input' }
    ];
    this.appointmentTimeList = [
      { subHeaderName: 'Week', subField: 'days', edit: true, type: 'selectWeek' },
      { subHeaderName: 'Time', subField: 'time', edit: true, type: 'timeSelect' },
    ];
    this.rowData['appointmentTiming'] = [];
    this.columnDefs.forEach((elm) => {
      this.rowData[elm.field] = '';
    });
    this.weekTimeArr = [
      {
        name: 'Sunday',
        subName: 'Sun',
        isChecked: false,
        timeArr: [
          {
            fromTime: {
              hour: 0, minute: 0
            },
            toTime: {
              hour: 0, minute: 0
            }
          }]
      },
      {
        name: 'Monday',
        subName: 'Mon',
        isChecked: false,
        timeArr: [
          {
            fromTime: {
              hour: 0, minute: 0
            },
            toTime: {
              hour: 0, minute: 0
            }
          }]
      },
      {
        name: 'Tuesday',
        subName: 'Tue',
        isChecked: false,
        timeArr: [
          {
            fromTime: {
              hour: 0, minute: 0
            },
            toTime: {
              hour: 0, minute: 0
            }
          }]
      },
      {
        name: 'Wednesday',
        subName: 'Wed',
        isChecked: false,
        timeArr: [
          {
            fromTime: {
              hour: 0, minute: 0
            },
            toTime: {
              hour: 0, minute: 0
            }
          }]
      },
      {
        name: 'Thursday',
        subName: 'Thu',
        isChecked: false,
        timeArr: [
          {
            fromTime: {
              hour: 0, minute: 0
            },
            toTime: {
              hour: 0, minute: 0
            }
          }]
      },
      {
        name: 'Friday',
        subName: 'Fri',
        isChecked: false,
        timeArr: [
          {
            fromTime: {
              hour: 0, minute: 0
            },
            toTime: {
              hour: 0, minute: 0
            }
          }]
      },
      {
        name: 'Saturday',
        subName: 'Sat',
        isChecked: false,
        timeArr: [
          {
            fromTime: {
              hour: 0, minute: 0
            },
            toTime: {
              hour: 0, minute: 0
            }
          }]
      }];
  }

  ngOnInit() {
    this.state_list = JsonReader.getJson("state-list.json");
    this.httpService.get('/api/programList').subscribe((data: any) => {
      data.forEach(element => {
        element['disabled'] = true;
        if (element.program_Id == 'PG03') {
          element['disabled'] = false;
        }
      });
      this.allProgramList = data;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
    this.listDataView();
  }
  listDataView() {
    this.httpService.get('/api/getFacilities').subscribe((res: any) => {

      res.forEach(element => {
        if (element.isOwner == 'common' || element.isOwner == this.client_name) {
          this.getFacilityListData.push(element);
        }
      });
      let length = this.getFacilityListData.length - 1;
      this.rowData.facilities_ID = 'F' + (parseInt((this.getFacilityListData[length].facilities_ID).replace('F', '')) + 1);
      this.rowData.isOwner = this.client_name;
      this.rowData.country = this.state_list.country;
      this.rowDataFacilityShow = true;
      this.rowData.programID = 'PG03';
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }
  addNewTimeSlot(event, mainIndex, innerIndex) {
    let timeObj = { fromTime: { hour: 0, minute: 0 }, toTime: { hour: 0, minute: 0 } };
    this.weekTimeArr[mainIndex].timeArr.push(timeObj);
  }
  removeNewTimeSlot(event, mainIndex, innerIndex) {
    this.weekTimeArr[mainIndex].timeArr.splice(innerIndex, 1);
  }
  addNewFacilityData(event) {
    this.weekTimeArr.forEach((weekObj) => {
      let tempTime = null;
      if (weekObj.isChecked) {
        let objTempTime = [];
        weekObj.timeArr.forEach(timeVal => {
          let fromPostfix = 'am';
          let toPostfix = 'am';
          let fromHour = this.util.preZero(timeVal.fromTime.hour);
          let fromMinute = this.util.preZero(timeVal.fromTime.minute);
          let toHour = this.util.preZero(timeVal.toTime.hour);
          let toMinute = this.util.preZero(timeVal.toTime.minute);
          if (parseInt(timeVal.fromTime.hour) > 12) {
            fromPostfix = 'pm';
            fromHour = this.util.preZero((parseInt(timeVal.fromTime.hour) - 12));
          }
          if (parseInt(timeVal.toTime.hour) > 12) {
            toPostfix = 'pm';
            toHour = this.util.preZero((parseInt(timeVal.toTime.hour) - 12));
          }
          let tempFinalString = fromHour + ':' + fromMinute + ' ' + fromPostfix + ' to ' + toHour + ':' + toMinute + ' ' + toPostfix;
          objTempTime.push(tempFinalString);
        });
        tempTime = objTempTime;
      }
      let timeObj = { days: weekObj.subName, time: tempTime }
      this.rowData.appointmentTiming.push(timeObj);
    });
    let obj = this.rowData;

    this.httpService.post('/api/saveFacilities', obj).subscribe((res: any) => {
      this.router.navigate(['/dashboard/facility']);
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    })
  }
  getSelectedStateVal(event) {
    this.selectedStateDistrictList = [];
    this.state_list.states.forEach(stateVal => {
      if (stateVal.state == this.rowData.state) {
        this.selectedStateDistrictList = stateVal.districts;
      }
    });
  }
}