import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AppRoutingModule } from './/app-routing.module';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SignupComponent } from './components/signup/signup.component';
import { LisitngViewComponent } from './components/lisitng-view/lisitng-view.component';
import { Ng4GeoautocompleteModule } from '../assets/js/ng4-geoautocomplete';
import { SortPipe } from './pipes/sort.pipe';
import { WindowService } from "./services/window.service";
import { DashboardComponent } from './components/dashboard/dashboard.component';

import { MenuComponent } from './components/menu/menu.component';
import { TopheaderComponent } from './components/topheader/topheader.component';
import { BookappointmentComponent } from './components/bookappointment/bookappointment.component';
import { BookhivtestComponent } from './components/bookhivtest/bookhivtest.component';
import { HeaderService } from './components/header/header.service';
import { AuthenticationService } from "./services/authentication.service";
import { HTTPListener, HTTPStatus } from "./helpers/httplistener";
import { LoaderComponent } from './components/loader/loader.component';
import { ChatComponent } from './components/chat/chat.component';
import { ChatService } from './services/chat.service';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import {NgxPaginationModule} from 'ngx-pagination';
import { AppointmentComponent } from './components/dashboard/appointment/appointment.component';
import { HivComponent } from './components/dashboard/hiv/hiv.component';
import { FacilityComponent } from './components/dashboard/facility/facility.component';
import { AgGridModule } from 'ag-grid-angular';
import { MessageComponent } from './components/message/message.component';
import { ChatClientComponent } from './components/dashboard/chat-client/chat-client.component';
import { SummaryDashboardComponent } from './components/dashboard/summary-dashboard/summary-dashboard.component';
import { ChatClientService } from './helpers/chat-client.service';
import { ActiveListComponent } from './components/dashboard/active-list/active-list.component';
import { AuthGuard } from './guards/auth.guard';
// Import your library
import { OwlModule } from 'ngx-owl-carousel';
import { CKEditorModule } from 'ng2-ckeditor';
import { ViewEditFacilityComponent } from './components/dashboard/facility/view-edit-facility/view-edit-facility.component';
import { AddNewFacilityComponent } from './components/dashboard/facility/add-new-facility/add-new-facility.component';
import { FilterPipe } from './pipes/filter.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { AdvertisementsComponent } from './components/dashboard/advertisements/advertisements.component';
import { AddAdvertisementComponent } from './components/dashboard/advertisements/add-advertisement/add-advertisement.component';
import { ViewEditAdvertisementComponent } from './components/dashboard/advertisements/view-edit-advertisement/view-edit-advertisement.component';
import { BannersectionsComponent } from './components/dashboard/bannersections/bannersections.component';
import { AddBannersectionComponent } from './components/dashboard/bannersections/add-bannersection/add-bannersection.component';
import { ViewEditBannersectionComponent } from './components/dashboard/bannersections/view-edit-bannersection/view-edit-bannersection.component';
import { ServicesComponent } from './components/dashboard/services/services.component';
import { AddServicesComponent } from './components/dashboard/services/add-services/add-services.component';
import { ViewEditServicesComponent } from './components/dashboard/services/view-edit-services/view-edit-services.component';
import { FileSelectDirective, FileDropDirective } from 'ng2-file-upload';
import { UploadImageComponent } from './components/dashboard/advertisements/upload-image/upload-image.component';
import { BasicHivComponent } from './components/basic-hiv/basic-hiv.component';
import { BasicsComponent } from './components/dashboard/basics/basics.component';
import { AddBasicsComponent } from './components/dashboard/basics/add-basics/add-basics.component';
import { ViewEditBasicsComponent } from './components/dashboard/basics/view-edit-basics/view-edit-basics.component';
import { BannerlistsComponent } from './components/dashboard/bannerlists/bannerlists.component';
import { AddBannerListComponent } from './components/dashboard/bannerlists/add-banner-list/add-banner-list.component';
import { ViewEditBannerListComponent } from './components/dashboard/bannerlists/view-edit-banner-list/view-edit-banner-list.component';
import { UploadBannersForListComponent } from './components/dashboard/bannerlists/upload-banners-for-list/upload-banners-for-list.component';
import { UploadMainBannerSectionComponent } from './components/dashboard/bannersections/upload-main-banner-section/upload-main-banner-section.component';
import { Util } from "./helpers/util";
import { SafePipe } from './pipes/safe.pipe';
import { AppInfoComponent } from './components/dashboard/app-info/app-info.component';
import { OrderComponent } from './components/order/order.component';
import { AboutComponent } from './components/about/about.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutAdminComponent } from './components/dashboard/about-admin/about-admin.component';
import { ContactAdminComponent } from './components/dashboard/contact-admin/contact-admin.component';
import { DisclaimerAdminComponent } from './components/dashboard/disclaimer-admin/disclaimer-admin.component';
import { PrivacyAdminComponent } from './components/dashboard/privacy-admin/privacy-admin.component';
import { ViewEditContactAdminComponent } from './components/dashboard/contact-admin/view-edit-contact-admin/view-edit-contact-admin.component';
import { AddContactAdminComponent } from './components/dashboard/contact-admin/add-contact-admin/add-contact-admin.component';
import { AddAboutAdminComponent } from './components/dashboard/about-admin/add-about-admin/add-about-admin.component';
import { ViewEditAboutAdminComponent } from './components/dashboard/about-admin/view-edit-about-admin/view-edit-about-admin.component';
import { ViewEditDisclaimerAdminComponent } from './components/dashboard/disclaimer-admin/view-edit-disclaimer-admin/view-edit-disclaimer-admin.component';
import { AddDisclaimerAdminComponent } from './components/dashboard/disclaimer-admin/add-disclaimer-admin/add-disclaimer-admin.component';
import { AddPrivacyAdminComponent } from './components/dashboard/privacy-admin/add-privacy-admin/add-privacy-admin.component';
import { ViewEditPrivacyAdminComponent } from './components/dashboard/privacy-admin/view-edit-privacy-admin/view-edit-privacy-admin.component';
import { CondomDetailsComponent } from './components/dashboard/condom-details/condom-details.component';
import { UserDetailsComponent } from './components/dashboard/user-details/user-details.component';
import { ExcelService } from './services/excel.service';
import { CounselingDetailsComponent } from './components/dashboard/counseling-details/counseling-details.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { RiskAssesmentComponent } from './components/dashboard/risk-assesment/risk-assesment.component';
const RxJS_Services = [HTTPListener, HTTPStatus];

@NgModule({
  declarations: [
    FileSelectDirective,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    SignupComponent,
    LisitngViewComponent,
    SortPipe,
    MenuComponent,
    DashboardComponent,
    TopheaderComponent,
    BookappointmentComponent,
    BookhivtestComponent,
    LoaderComponent,
    ChatComponent,
    AppointmentComponent,
    HivComponent,
    FacilityComponent,
    MessageComponent,
    ChatClientComponent,
    SummaryDashboardComponent,
    ActiveListComponent,
    ViewEditFacilityComponent,
    AddNewFacilityComponent,
    FilterPipe,
    PhonePipe,
    AdvertisementsComponent,
    AddAdvertisementComponent,
    ViewEditAdvertisementComponent,
    BannersectionsComponent,
    AddBannersectionComponent,
    ViewEditBannersectionComponent,
    ServicesComponent,
    AddServicesComponent,
    ViewEditServicesComponent,
    UploadImageComponent,
    BasicHivComponent,
    BasicsComponent,
    AddBasicsComponent,
    ViewEditBasicsComponent,
    BannerlistsComponent,
    AddBannerListComponent,
    ViewEditBannerListComponent,
    UploadBannersForListComponent,
    UploadMainBannerSectionComponent,
    SafePipe,
    AppInfoComponent,
    OrderComponent,
    AboutComponent,
    PrivacyPolicyComponent,
    DisclaimerComponent,
    ContactComponent,
    AboutAdminComponent,
    ContactAdminComponent,
    DisclaimerAdminComponent,
    PrivacyAdminComponent,
    ViewEditContactAdminComponent,
    AddContactAdminComponent,
    AddAboutAdminComponent,
    ViewEditAboutAdminComponent,
    ViewEditDisclaimerAdminComponent,
    AddDisclaimerAdminComponent,
    AddPrivacyAdminComponent,
    ViewEditPrivacyAdminComponent,
    CondomDetailsComponent,
    UserDetailsComponent,
    CounselingDetailsComponent,
    RiskAssesmentComponent
  ],
  imports: [
    BrowserModule,
    OwlModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    Ng4GeoautocompleteModule.forRoot(),
    MalihuScrollbarModule.forRoot(),
    NgxPaginationModule,
    CKEditorModule,
    MatRadioModule,
    MatProgressBarModule,
    AgGridModule.withComponents([])
  ],
  exports: [
    HeaderComponent,
    HttpClientModule
  ],
  providers: [WindowService,
    HeaderService,
    AuthenticationService,
    RxJS_Services,
    ExcelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true
    },
    ChatService,
    ChatClientService,
    AuthGuard,Util],
  bootstrap: [AppComponent]
})
export class AppModule { }
