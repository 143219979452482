import { Injectable, EventEmitter } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { LocalStorage } from '../helpers/local-storage';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
  private authToken: string;
  private user: any;
  private clientName: string = environment.client_name;
  isLoggedInUser: boolean = false;
  isLoggendInUserChange: Subject<boolean> = new Subject<boolean>();
  constructor() { }
  isAuthenticated(val) {
    this.isLoggendInUserChange.next(val);
  }
  storeUserData(token, user): void {
    LocalStorage.setItem('jwt' + this.clientName + 'Token', token);
    LocalStorage.setItem('userInfo' + this.clientName + '', user);
    this.authToken = token;
    this.user = user;
  }

  getUserData(): any {
    this.loadCredentials();
    let jUser = this.user;
    let jData = { token: this.authToken, user: jUser };
    return jData;
  }
  userRole(): any {
    this.loadCredentials();
    let role = this.user != undefined ? this.user.isRole : '';
    return role;
  }
  userToken(): any {
    this.loadCredentials();
    return this.authToken;
  }

  loadCredentials(): void {
    let token = LocalStorage.getItem('jwt' + this.clientName + 'Token');
    let user = LocalStorage.getItem('userInfo' + this.clientName + '');
    this.authToken = (token === undefined || token === null) ? undefined : token;
    this.user = (user === undefined || user === null)? undefined:user;
  }

  logout(): void {
    this.authToken = null;
    this.user = null;
  }
  setKeyValLocal(key, val): void{
    LocalStorage.setItem('' + this.clientName + ''+key+'', val);
  }
  getKeyValLocal(key): any{
    let data = LocalStorage.getItem('' + this.clientName + ''+key+'');
    return data;
  }
  removeKeyLocal(key): void{
    LocalStorage.removeItem('' + this.clientName + ''+key+'');
  }

}
