import { Component, OnInit, Inject } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'app-upload-banners-for-list',
  templateUrl: './upload-banners-for-list.component.html',
  styleUrls: ['./upload-banners-for-list.component.scss']
})
export class UploadBannersForListComponent implements OnInit {

  public uploader: FileUploader = new FileUploader({ url: '',allowedMimeType : ['image/jpeg', 'image/jpg', 'image/tiff', 'image/gif', 'image/bmp', 'image/png', 'image/bpg', 'image/svg', 'image/svg+xml'] });
  selectedBannerId: any;
  bannerId: any;
  title: string = 'Upload Banners';
  rowData: any;
  domain: any;
  response: any = '';
  uploadSuccessMegShow: boolean = false;
  rowDataAdvertisementShow: boolean = false;
  uploadBtnAfterMimeTypeCheck: boolean=false;
  uploadBtnAfterMimeTypeCheckError: boolean = false;
  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private route: ActivatedRoute, private httpService: HttpClient, private mScrollbarService: MalihuScrollbarService) {
    this.uploader.options.url = this.document.baseURI + 'api/upload';
    let _self = this;
    this.uploader.onAfterAddingFile = function (fileItem) {
      _self.uploadBtnAfterMimeTypeCheck = true;
      _self.uploadBtnAfterMimeTypeCheckError = false;
      return { fileItem: fileItem };
  };
    this.uploader.onWhenAddingFileFailed = function (item, filter, options) {
      _self.uploadBtnAfterMimeTypeCheck = false;
      _self.uploadBtnAfterMimeTypeCheckError = true;
      return { item: item, filter: filter, options: options };
  };
    this.uploader.onSuccessItem = function (item, response, status, headers) {
      let obj = {
        'response': response
      }
      _self.httpService.post('/api/setBannerListPath/' + _self.bannerId, obj).subscribe((res: any) => {
        _self.uploadSuccessMegShow = true;
        setTimeout(() => {
          _self.uploadSuccessMegShow = false;
          setTimeout(() => {
            _self.router.navigate(['/dashboard/bannerlists']);
          }, 2000);
        }, 2000);
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      })
      return { item: item, response: response, status: status, headers: headers };
    };
    this.route.params.subscribe(params => {
      this.bannerId = params['id'];
    });
  }

  ngOnInit() {
    this.listDataView();
  }

  listDataView() {
    this.httpService.get('/api/getBannerList/' + this.bannerId).subscribe((res: any) => {
      this.rowData = res[0];
      this.rowDataAdvertisementShow = true;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }

}
