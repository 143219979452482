import { Component, OnInit } from '@angular/core';
import {Validators, FormBuilder, FormGroup} from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';

@Component({
  selector: 'app-view-edit-services',
  templateUrl: './view-edit-services.component.html',
  styleUrls: ['./view-edit-services.component.scss']
})
export class ViewEditServicesComponent implements OnInit {
  serviceId: any;
  isEditable: boolean= false;
  title: string;
  rowData: any;
  rowDataServicesShow: boolean = false;
  columnDefs: Array<any> = [];
  constructor(private router: Router, private route: ActivatedRoute,private httpService: HttpClient,private mScrollbarService: MalihuScrollbarService) { 
    this.columnDefs = [
      { headerName: 'Services ID', field: 'services_Id', edit: false, type:'input'},
      { headerName: 'Service Name', field: 'services_name',edit: true, type:'input'},
      { headerName: 'Service Icon', field: 'services_icon',edit: true, type:'input' },
      { headerName: 'Service Agent', field: 'agent_name',edit: true, type:'input' }
  ];
  this.route.params.subscribe(params => {
    this.serviceId = params['id'];
    this.isEditable = params['isEditable'] == 'true'? true: false;
    });
  }

  ngOnInit() {
    if (this.isEditable) {
      this.title = 'Edit Service';
    }
    else {
      this.title = 'View Service';
    }
    this.listDataView();
  }

  listDataView() {
    this.httpService.get('/api/serviceList/' +this.serviceId).subscribe((res: any) => {
      this.rowData = res[0];
      this.rowDataServicesShow = true;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }

  updateServicesData() {
    let obj = this.rowData;
    this.httpService.post('/api/setServices/'+ this.serviceId, obj).subscribe((res: any) => {
      this.router.navigate(['/dashboard/services']);
     }, (err: HttpErrorResponse) => {
      console.log(err.message);
    })
  }

}
