import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { Observable ,  of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AuthenticationService } from "../../services/authentication.service";
import { LocalStorage } from "../../helpers/local-storage";
import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/messaging';
import { environment } from "../../../environments/environment";
import { WindowService } from "../../services/window.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  signInData: any = { mobileNumber: '', password: '' };
  message = '';
  data: any;
  hideSignInForm: boolean = false;
  showNotExistUser: boolean = false;
  messageSent: boolean = false;
  completeMobileNumer: string;
  enableProceedBtn: boolean = false;
  smsSentFailed: boolean = false;
  smsSentFailedMsg: any;
  showPwdBox: boolean = true;
  returnUrl: any;
  isLoggedInUser: boolean = false;
  isCounsellor: boolean = false;
  forgetPwdMainBox: boolean = false;
  windowRef: any;
  mobileNumberAlt: any = '';
  smsOTPVerifyError: boolean = false;
  smsOTPVarifyErrorMsg: any;
  showAuthenticationValid: boolean = false;
  verificationCode: string = '';
  resetPassword: any = '';
  resetMobileId: any;
  showResetNewPwdFinalStage: boolean =false;
  constructor(private http: HttpClient, private router: Router, private authenticationService: AuthenticationService, private win: WindowService) {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
  }

  ngOnInit() {
    if (this.authenticationService.userToken() != undefined) {
      this.isLoggedInUser = true;
      this.router.navigate(['home']);
    }
    else {
      this.windowRef = this.win.windowRef;
    }
  }
  login() {
    this.http.post('/api/findMobileNumberPwd', this.signInData).subscribe((resp: any) => {
      if (resp.success == true) {
        this.showNotExistUser = false;
        this.confirmLogin();
      }
      else if (resp.success == false && resp.type == 'password') {
        this.smsSentFailed = true;
        this.smsSentFailedMsg = resp.msg;
      }
      else {
        this.hideSignInForm = true;
        this.showNotExistUser = true;
      }
    }, err => {
      console.log(err);
    });

  }
  confirmLogin() {
    this.http.post('/api/signin', this.signInData).subscribe(resp => {
      this.data = resp;
      this.authenticationService.storeUserData(this.data.token, this.data.userInfo);

      this.authenticationService.isAuthenticated(true);
      this.returnUrl = this.authenticationService.getKeyValLocal('previousUrl');
      // login successful so redirect to return url
      if (this.returnUrl == undefined || this.returnUrl == '/login' || this.returnUrl == '/signup') {
        this.router.navigate(['home']);
      }
      else {
        this.authenticationService.removeKeyLocal('previousUrl');
        if (this.data.userInfo.isRole == "Counsellor" || this.data.userInfo.isRole == "Admin") {
          this.isCounsellor = true;
          this.router.navigate(['dashboard']);
        }
        else {
          this.router.navigateByUrl(this.returnUrl);
          this.isCounsellor = false;
        }
      }
    }, err => {
      this.message = err.error.msg;
    });
  }
  sendOtp() {
    let obj: any = { 'mobileNumber': this.mobileNumberAlt };
    this.http.post('/api/findMobileNumber', obj).subscribe((resp: any) => {
      if (resp.success == true) {
        this.smsSentFailed = false;
        this.resetMobileId = resp.id;
        this.messageSent = true;
        this.sendOtpSms();
      }
      else {
        this.smsSentFailed = true;
        this.messageSent = false;
        this.smsSentFailedMsg = resp.msg;
      }
    }, err => {
      console.log(err);
    });

  }
  sendOtpSms() {
    const appVerifier = this.windowRef.recaptchaVerifier;
    const phoneNumberString = '+91 ' + this.mobileNumberAlt.toString();
    this.completeMobileNumer = phoneNumberString;
    firebase.auth().signInWithPhoneNumber(phoneNumberString, appVerifier)
      .then((result) => {
        this.smsSentFailed = false;
        this.windowRef.confirmationResult = result;
      })
      .catch((err) => {
        this.smsSentFailed = true;
        this.smsSentFailedMsg = err.message;
      });
  }
  verifyLoginCode() {
    this.windowRef.confirmationResult
      .confirm(this.verificationCode)
      .then(result => {
        this.smsOTPVerifyError = false;
        this.smsOTPVarifyErrorMsg = '';
        this.hideSignInForm = true;
        this.forgetPwdMainBox = false;
        this.showPwdBox = true;
        this.showResetNewPwdFinalStage = true;
      })
      .catch(error => {
        this.smsOTPVerifyError = true;
        this.smsOTPVarifyErrorMsg = error.message;
      });
  }

  resetPasswordFn() {
    let obj = { 'id': this.resetMobileId, 'password': this.resetPassword };
    this.http.post('/api/resetPwd', obj).subscribe(resp => {
      let data: any = resp;
      this.authenticationService.storeUserData(data.token, data.userInfo);
      this.authenticationService.isAuthenticated(true);
      if (data.userInfo.isRole == "Counsellor" || data.userInfo.isRole == "Admin") {
        this.isCounsellor = true;
        this.router.navigate(['dashboard']);
      }
      else {
        this.router.navigate(['home']);
      }
    }, err => {
      this.message = err.error.msg;
    });
  }
  resendOtplink() {
    this.verificationCode = '';
    this.smsOTPVerifyError = false;
    this.smsOTPVarifyErrorMsg = '';
    this.sendOtpSms();
  }
  forgotPwd() {
    this.smsSentFailed = false;
    this.smsSentFailedMsg = '';
    this.forgetPwdMainBox = true;
    var _this = this;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('send-otp-button', {
      'size': 'invisible',
      'callback': function (response) {
        _this.sendOtp();
      }
    });
    this.windowRef.recaptchaVerifier.render();
  }
}