import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { environment } from '../../../../../environments/environment';
import { DOCUMENT } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-add-privacy-admin',
  templateUrl: './add-privacy-admin.component.html',
  styleUrls: ['./add-privacy-admin.component.scss']
})
export class AddPrivacyAdminComponent implements OnInit {


  public uploader: FileUploader = new FileUploader({ url: '', allowedMimeType: ['image/jpeg', 'image/jpg', 'image/tiff', 'image/gif', 'image/bmp', 'image/png', 'image/bpg', 'image/svg', 'image/svg+xml','video/mpeg','video/mp4','video/quicktime','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/pdf','application/vnd.ms-powerpoint','application/vnd.openxmlformats-officedocument.presentationml.presentation'] });
  @ViewChild('uploaderElmnt') uploaderElmntBox: ElementRef;
  rowData: any={};
  rowDataPrivacyPolicyShow: boolean = false;
  columnDefs: Array<any> = [];
  client_name: any = environment.client_name;
  generatedLinkVal: any = '';
  uploadBtnAfterMimeTypeCheck: boolean = false;
  uploadBtnAfterMimeTypeCheckError: boolean = false;
  linkGenerated: boolean = false;
  ckConfig: any;
  constructor(@Inject(DOCUMENT) private document: any,private router: Router, private route: ActivatedRoute,private httpService: HttpClient,private mScrollbarService: MalihuScrollbarService) { 
    this.uploader.options.url = this.document.baseURI + 'api/upload';
    let _self = this;
    this.uploader.onAfterAddingFile = function (fileItem) {
      _self.uploadBtnAfterMimeTypeCheck = true;
      _self.uploadBtnAfterMimeTypeCheckError = false;
      return { fileItem: fileItem };
    };
    this.uploader.onWhenAddingFileFailed = function (item, filter, options) {
      _self.uploadBtnAfterMimeTypeCheck = false;
      _self.uploadBtnAfterMimeTypeCheckError = true;
      return { item: item, filter: filter, options: options };
    };
    this.uploader.onSuccessItem = function (item, response, status, headers) {
      _self.uploaderElmntBox.nativeElement.value = '';
      _self.uploadBtnAfterMimeTypeCheck = false;
      _self.generatedLinkVal = _self.document.baseURI + '' + response;
      _self.linkGenerated = true;
      return { item: item, response: response, status: status, headers: headers };
    };
    this.columnDefs = [
      { headerName: 'privacyPolicy ID', field: 'privacyPolicy_Id', edit: false, type:'input'},
      { headerName: 'privacyPolicy Icon', field: 'privacyPolicyIcon',edit: true, type:'input'},
      { headerName: 'privacyPolicy Heading', field: 'privacyPolicyHeading',edit: true, type:'input'},
      { headerName: 'privacyPolicy Content', field: 'privacyPolicyContent',edit: true, type:'ckeditor' },
      ];
  }

  ngOnInit() {
    this.ckConfig =
    {
      extraPlugins: 'divarea,embed,embedbase',
      embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}'
    };
    this.listDataView();
  }

  listDataView() {

    this.httpService.get('/api/getprivacys').subscribe((res: any) => {
        // this.rowData = res;
      if (res.length == 0) {
        this.rowData.privacyPolicy_Id = 'PP1';
      }
      else {
        let length = res.length - 1;
        this.rowData.privacyPolicy_Id = 'PP' + (parseInt((res[length].privacyPolicy_Id).replace('PP', '')) + 1);
      }
       this.rowDataPrivacyPolicyShow = true;
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }


  addNewPrivacyPolicyData(event) {
    let obj = this.rowData;
    this.httpService.post('/api/saveprivacys', obj).subscribe((res: any) => {
      this.router.navigate(['/dashboard/privacyPolicys']);
     }, (err: HttpErrorResponse) => {
      console.log(err.message);
    })
  }

}
