// The purpose of this class is to remove repetitive JSON.stringify & JSON.parse
export class LocalStorage {
    public static setItem(key, data){
        localStorage.setItem(key, JSON.stringify(data));
    }

    public static getItem(key){
        return JSON.parse(localStorage.getItem(key));
    }

    public static removeItem(key) {
        localStorage.removeItem(key);
    }

    /**
     * clear everything from local Storage
     * 
     * @static
     * @memberof LocalStorage
     */
    public static clearStorage(){
        localStorage.clear();
    }
}
