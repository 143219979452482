import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-add-advertisement',
  templateUrl: './add-advertisement.component.html',
  styleUrls: ['./add-advertisement.component.scss']
})
export class AddAdvertisementComponent implements OnInit {

 //getFacilityListData: Array<any>=[];
  rowData: any={};
  rowDataAdvertisementShow: boolean = false;
  columnDefs: Array<any> = [];
  client_name: any = environment.client_name;
  advServicesList: Array<any>=[];

  constructor(private router: Router, private route: ActivatedRoute,private httpService: HttpClient,private mScrollbarService: MalihuScrollbarService) {
    this.columnDefs = [
        { headerName: 'Advertisements ID', field: 'advertisement_Id', edit: false, type:'input'},
        { headerName: 'Advertisement Type', field: 'advertisementIsType',edit: true, type:'select'},
        { headerName: 'Advertisement Heading', field: 'advertisementHeading',edit: true, type:'input' },
        { headerName: 'Advertisement Second Heading', field: 'advertisementSecondHeading',edit: true, type:'input' },
        { headerName: 'Advertisement Services', field: 'advertisementServices',edit: true, type:'input' }
    ];
    this.advServicesList = [
      { subHeaderName: 'Service ID', subField: 'services_ID', edit: false, type: 'input' },
      { subHeaderName: 'Service Name', subField: 'serviceName', edit: true, type: 'input' },
      { subHeaderName: 'Service Description', subField: 'serviceDescription', edit: true, type: 'ckeditor' },
      { subHeaderName: 'Service Video Url', subField: 'serviceVideoSrc',edit: true, type:'videoUrl' }
    ];
    this.columnDefs.forEach((elm) => {
      this.rowData[elm.field] = '';
    });
    this.rowData['advertisementListServices'] = [];
    let innerObj = {};
    this.advServicesList.forEach((elm) => {
      innerObj[elm.subField] = '';
      if (elm.subField == 'services_ID') {
        innerObj[elm.subField] = 'SE1';
      }
    });
    this.rowData.advertisementListServices.push(innerObj);
   }

  ngOnInit() {
    this.listDataView();
  }
  listDataView() {

    this.httpService.get('/api/getAdvertisements').subscribe((res: any) => {
        // this.rowData = res;
      if (res.length == 0) {
        this.rowData.advertisement_Id = 'ADV1';
      }
      else {
        let length = res.length - 1;
        this.rowData.advertisement_Id = 'ADV' + (parseInt((res[length].advertisement_Id).replace('ADV', '')) + 1);
      }
       this.rowDataAdvertisementShow = true;
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }
  addNewAdvertisementService() {
    let lengthService = this.rowData.advertisementListServices.length;
    let innerObj = {};
    this.advServicesList.forEach((elm) => {
      innerObj[elm.subField] = '';
      if (elm.subField == 'services_ID') {
        innerObj[elm.subField] = 'SE'+ (parseInt(lengthService) + 1);
      }
    });
    this.rowData.advertisementListServices.push(innerObj);
  }
  removeNewAdvertisementService(event, removeIndex) {
    this.rowData.advertisementListServices.splice(removeIndex , 1);
  }
  addNewAdvertisementData(event) {
    let obj = this.rowData;
    this.httpService.post('/api/saveAdvertisements', obj).subscribe((res: any) => {
      this.router.navigate(['/dashboard/advertisements']);
     }, (err: HttpErrorResponse) => {
      console.log(err.message);
    })
  }
}