import { Component, OnInit, Inject, Injectable, OnChanges } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  public uploader: FileUploader = new FileUploader({ url: '', allowedMimeType: ['image/jpeg', 'image/jpg', 'image/tiff', 'image/gif', 'image/bmp', 'image/png', 'image/bpg', 'image/svg', 'image/svg+xml'] });
  public uploader1: FileUploader = new FileUploader({ url: '',allowedMimeType : ['image/jpeg', 'image/jpg', 'image/tiff', 'image/gif', 'image/bmp', 'image/png', 'image/bpg', 'image/svg','image/svg+xml'] });
  selectedServiceBanner: any;
  advertisementId: any;
  listOfServices: Array<any> = [];
  title: string = 'Upload Service Banners';
  rowData: any;
  rowDataAdvertisementShow: boolean = false;
  domain: any;
  response: any = '';
  showUploaderInput: boolean = false;
  uploadSuccessMegShow: boolean = false;
  uploadBtnAfterMimeTypeCheck: boolean = false;
  uploadBtnAfterMimeTypeCheckError: boolean = false;
  upload1SuccessMegShow: boolean = false;
  upload1BtnAfterMimeTypeCheck: boolean = false;
  upload1BtnAfterMimeTypeCheckError: boolean = false;
  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private route: ActivatedRoute, private httpService: HttpClient, private mScrollbarService: MalihuScrollbarService) {
    this.uploader.options.url = this.document.baseURI + 'api/upload';
    this.uploader1.options.url = this.document.baseURI + 'api/upload';
    let _self = this;
    this.uploader.onAfterAddingFile = function (fileItem) {
      _self.uploadBtnAfterMimeTypeCheck = true;
      _self.uploadBtnAfterMimeTypeCheckError = false;
      return { fileItem: fileItem };
  };
    this.uploader.onWhenAddingFileFailed = function (item, filter, options) {
      _self.uploadBtnAfterMimeTypeCheck = false;
      _self.uploadBtnAfterMimeTypeCheckError = true;
      return { item: item, filter: filter, options: options };
  };
    this.uploader.onSuccessItem = function (item, response, status, headers) {
      let obj = {
        'response': response,
        'serviceId': _self.selectedServiceBanner
      }
      _self.httpService.post('/api/setAdvertisementsServiceBannerPath/' + _self.advertisementId, obj).subscribe((res: any) => {
        _self.showUploaderInput = false;
        _self.uploadSuccessMegShow = true;
        _self.selectedServiceBanner = undefined;
        setTimeout(() => {
          _self.uploadSuccessMegShow = false;
        }, 2000);
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      })
      return { item: item, response: response, status: status, headers: headers };
    };
    this.uploader1.onAfterAddingFile = function (fileItem) {
      _self.upload1BtnAfterMimeTypeCheck = true;
      _self.upload1BtnAfterMimeTypeCheckError = false;
      return { fileItem: fileItem };
  };
    this.uploader1.onWhenAddingFileFailed = function (item, filter, options) {
      _self.upload1BtnAfterMimeTypeCheck = false;
      _self.upload1BtnAfterMimeTypeCheckError = true;
      return { item: item, filter: filter, options: options };
  };
    this.uploader1.onSuccessItem = function (item, response, status, headers) {
      let obj = {
        'response': response
      }
      _self.httpService.post('/api/setAdvertisementsBannerBgImgPath/' + _self.advertisementId, obj).subscribe((res: any) => {
        _self.upload1SuccessMegShow = true;
        setTimeout(() => {
          _self.upload1SuccessMegShow = false;
        }, 2000);
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      })
      return { item: item, response: response, status: status, headers: headers };
    };

    this.route.params.subscribe(params => {
      this.advertisementId = params['id'];
    });
  }

  ngOnInit() {
    this.listDataView();
  }

  servicesListValChange() {
    this.showUploaderInput = true;
  }
  listDataView() {
    this.httpService.get('/api/getAdvertisements/' + this.advertisementId).subscribe((res: any) => {
      this.rowData = res[0];
      if (this.rowData.advertisementIsType == 'video') {
        this.uploader.options.allowedMimeType = this.uploader.options.allowedMimeType.concat(['video/mpeg','video/mp4','video/quicktime']);
      }
      this.rowData.advertisementListServices.forEach(element => {
        this.listOfServices.push({ 'value': element.services_ID, 'label': element.serviceName });
      });
      this.rowDataAdvertisementShow = true;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }
}
