import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { HeaderService } from '../../header/header.service';
import { environment } from '../../../../environments/environment';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.scss']
})
export class FacilityComponent implements OnInit {
  @ViewChild('facilityScrollBody') facilityScrollBodyElmnt;
  client_name: any = environment.client_name;
  columnDefs: Array<any> = [];
  getFacilityListData: Array<any>=[];
  rowData: Array<any>=[];
  constructor(private httpService: HttpClient, private headerService: HeaderService,
    private mScrollbarService: MalihuScrollbarService,
    private modalService: NgbModal) {
    this.columnDefs = [
      { headerName: 'Facilities ID', field: 'facilities_ID'},
      { headerName: 'program ID', field: 'programID'},
      { headerName: 'Facility Name', field: 'facility_name' },
      { headerName: 'Hospital Name', field: 'hospital_name' },
      { headerName: 'Owner', field: 'isOwner' }
    ];

   }

  ngOnInit() {
    this.listDataView();
  }
  listDataView() {
    this.httpService.get('/api/getFacilities').subscribe((res: any) => {

      res.forEach(element => {
        if (element.isOwner == this.client_name) {
          this.getFacilityListData.push(element);
        }
      });
      this.rowData = this.getFacilityListData;

    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }

  openVerticallyCentered(content, id) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.httpService.delete('/api/deleteFacilities/' + id).subscribe((res: any) => {
        this.rowData = [];
        this.listDataView();
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      })
    }, (reason) => {
      console.log(reason);
    });
  }

  ngAfterViewInit() {
    this.mScrollbarService.initScrollbar(this.facilityScrollBodyElmnt.nativeElement, { axis: 'y', theme: 'dark', advanced: { autoExpandHorizontalScroll: true } });
  }

}