import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';

@Component({
  selector: 'app-active-list',
  templateUrl: './active-list.component.html',
  styleUrls: ['./active-list.component.scss']
})
export class ActiveListComponent implements OnInit {
  @Input() users: Array<String>;
  @Input() current: string;
  @Output() newConv = new EventEmitter<string>();
  @ViewChild('activeListScroll') activeListScrollElmnt;
  usersLists: Array<any> = [];
  constructor(private mScrollbarService: MalihuScrollbarService, ) { }
  ngOnChanges() {
    this.usersLists = this.users
  }
  ngOnInit() {
  }
  ngAfterViewInit() {
    this.mScrollbarService.initScrollbar(this.activeListScrollElmnt.nativeElement, { axis: 'y', theme: 'dark' });
  }
  onUserClick(username: string): boolean {
    this.newConv.emit(username);
    return false;
  }

}
