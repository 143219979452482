
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { DOCUMENT } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-view-edit-contact-admin',
  templateUrl: './view-edit-contact-admin.component.html',
  styleUrls: ['./view-edit-contact-admin.component.scss']
})
export class ViewEditContactAdminComponent implements OnInit {

  public uploader: FileUploader = new FileUploader({ url: '', allowedMimeType: ['image/jpeg', 'image/jpg', 'image/tiff', 'image/gif', 'image/bmp', 'image/png', 'image/bpg', 'image/svg', 'image/svg+xml','video/mpeg','video/mp4','video/quicktime','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/pdf','application/vnd.ms-powerpoint','application/vnd.openxmlformats-officedocument.presentationml.presentation'] });
  contactId: any;
  @ViewChild('uploaderElmnt') uploaderElmntBox: ElementRef;
  isEditable: boolean = false;
  title: string;
  rowData: any;
  rowDataContactShow: boolean = false;
  columnDefs: Array<any> = [];
  ckConfig: any;
  generatedLinkVal: any = '';
  uploadBtnAfterMimeTypeCheck: boolean = false;
  uploadBtnAfterMimeTypeCheckError: boolean = false;
  linkGenerated: boolean = false;
  constructor(@Inject(DOCUMENT) private document: any,private router: Router, private route: ActivatedRoute, private httpService: HttpClient, private mScrollbarService: MalihuScrollbarService) {
    this.uploader.options.url = this.document.baseURI + 'api/upload';
    let _self = this;
    this.uploader.onAfterAddingFile = function (fileItem) {
      _self.uploadBtnAfterMimeTypeCheck = true;
      _self.uploadBtnAfterMimeTypeCheckError = false;
      return { fileItem: fileItem };
    };
    this.uploader.onWhenAddingFileFailed = function (item, filter, options) {
      _self.uploadBtnAfterMimeTypeCheck = false;
      _self.uploadBtnAfterMimeTypeCheckError = true;
      return { item: item, filter: filter, options: options };
    };
    this.uploader.onSuccessItem = function (item, response, status, headers) {
      _self.uploaderElmntBox.nativeElement.value = '';
      _self.uploadBtnAfterMimeTypeCheck = false;
      _self.generatedLinkVal = _self.document.baseURI + '' + response;
      _self.linkGenerated = true;
      return { item: item, response: response, status: status, headers: headers };
    };
    this.columnDefs = [
      { headerName: 'Contact ID', field: 'contact_Id', edit: false, type:'input'},
      { headerName: 'Contact Icon', field: 'contactIcon',edit: true, type:'input'},
      { headerName: 'Contact Heading', field: 'contactHeading',edit: true, type:'input'},
      { headerName: 'Contact Content', field: 'contactContent',edit: true, type:'ckeditor' },
    ];
    this.route.params.subscribe(params => {
      this.contactId = params['id'];
      this.isEditable = params['isEditable'] == 'true' ? true : false;
    });
  }

  ngOnInit() {
    this.ckConfig =
      {
        extraPlugins: 'divarea,embed,embedbase',
        embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}'
      };
    if (this.isEditable) {
      this.title = 'Edit Contact';
    }
    else {
      this.title = 'View Contact';
      this.ckConfig.toolbar = [{ name: 'empty', items: [] }];
    }
    this.listDataView();
  }

  listDataView() {
    this.httpService.get('/api/getcontacts/' + this.contactId).subscribe((res: any) => {
      this.rowData = res[0];
      this.rowDataContactShow = true;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }

  updateContactData() {
    let obj = this.rowData;
    this.httpService.post('/api/setcontacts/' + this.contactId, obj).subscribe((res: any) => {
      this.router.navigate(['/dashboard/contacts']);
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    })
  }


}
