import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { HeaderService } from '../../header/header.service';
import { environment } from '../../../../environments/environment';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  @ViewChild('serviceScrollBody') serviceScrollBodyElmnt;
  client_name: any = environment.client_name;
  columnDefs: Array<any> = [];
  rowData: Array<any> = [];

  constructor(private httpService: HttpClient, private headerService: HeaderService, private modalService: NgbModal, private mScrollbarService: MalihuScrollbarService) {

    this.columnDefs = [
      { headerName: 'Service ID', field: 'services_Id' },
      { headerName: 'Service Name', field: 'services_name' },
      { headerName: 'Service Icon', field: 'services_icon' },
      { headerName: 'Service Agent', field: 'agent_name' }
    ];
  };


  ngOnInit() {
    this.listDataView();
  }
  listDataView() {
    this.httpService.get('/api/serviceList').subscribe((res: any) => {
      this.rowData = res;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  };

  openVerticallyCentered(content, id) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.httpService.delete('/api/deleteServices/' + id).subscribe((res: any) => {
        this.rowData = [];
        this.listDataView();
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
      });
    }, (reason) => {
      console.log(reason);
    });
  }

  ngAfterViewInit() {
    this.mScrollbarService.initScrollbar(this.serviceScrollBodyElmnt.nativeElement, { axis: 'y', theme: 'dark', advanced: { autoExpandHorizontalScroll: true } });
  }
}
