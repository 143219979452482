import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { SortPipe } from "../../pipes/sort.pipe";
import {NgbAccordionConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [NgbAccordionConfig] 
})
export class ContactComponent implements OnInit {

  ckConfig: any;
  resultFound: Array<any> = [];
  constructor(private httpService: HttpClient,private modalService: NgbModal, private router: Router, private config: NgbAccordionConfig) { 
    config.closeOthers = true;
    config.type = 'info';
  }

  ngOnInit() {
    this.ckConfig =
      {
        extraPlugins: 'divarea,embed,embedbase',
        embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
        toolbar: [{ name: 'empty', items: [] }]
      };
    this.httpService.get('/api/getcontacts').subscribe((data: any) => {
      this.resultFound = data;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }


}
