import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';

import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { Message } from '../models/message.model';
import { Injectable } from '@angular/core';

@Injectable()
export class ChatClientService {
  private socket: any;
  activeUser = [];
  constructor(private http:HttpClient, private authenticationService:AuthenticationService ) { }
  connect(fullName: string, callback: Function = ()=>{}): void {
    // initialize the connection
    this.socket = io({ path: '/privateChat'});

    this.socket.on('error', (error) => {
      
    });

    this.socket.on("connect", () => {
      this.sendUser(fullName);
      console.log("connected to the chat server");
      callback();
    });
  }

  isConnected(): boolean {
    if (this.socket != null) {
      return true;
    } else {
      return false;
    }
  }
  sendUser(fullName: string): void {
    this.socket.emit("fullName", {fullName: fullName});
  }
 
  getConversation(name1: string, name2:string):any {
    return new Promise((resolve, reject) => {
      this.http.get('/realTimeChat/' + name1 +'/'+ name2).toPromise().then(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
  receiveMessage(): any {
    let observable = new Observable(observer => {
      this.socket.on("message", (data: Message) => {
        observer.next(data);
      });
    });

    return observable;
  }

  receiveActiveList(): any {
    this.activeUser = [];
    let observable = new Observable(observer => {
      this.socket.on("active", (data) => {
        observer.next(data);
         data.forEach(element => {
          this.activeUser.push(element.fullName);
        });
      });
    });

    return observable;
  }
  getUserList() {
    return new Promise((resolve, reject) => {
      this.http.get('/api/userList').toPromise().then(res => {
        res['users'].forEach(all => {
          this.activeUser.forEach(active => {
            if (all.fullName == active) {
              all['activeNow'] = true;
            }
          });
        });

          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
  getActiveList(): void {
    this.socket.emit("getactive");
  }
  sendMessage(message: Message, chatWith: string): void {
    this.socket.emit("message", {message: message, to: chatWith});
  }
  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
} 
