import { Injectable } from '@angular/core';
import { LocalStorage } from './local-storage';

@Injectable()
export class Util {
  private colorArrayAssign: any[];
  private colorArrayMain: any[];

  constructor() { }

  getShortDate(param) {
    var dateValue = new Date(param);
    var month = dateValue.getMonth() + 1;
    var day = dateValue.getDate();
    var year = dateValue.getFullYear();
    var validDate = this.dateFormatter(month + '/' + day + '/' + year, 'm/d/Y');
    return validDate;
  }

  getFullDateTimeUTC() {
    var d = new Date,
      dformat = [(d.getUTCMonth() + 1).toString().length == 1 ? '0' + (d.getUTCMonth() + 1).toString() : (d.getUTCMonth() + 1).toString(),
      d.getUTCDate().toString().length == 1 ? '0' + d.getUTCDate().toString() : d.getUTCDate().toString(),
      d.getUTCFullYear()].join('/')
        + ' '
        + [d.getUTCHours().toString().length == 1 ? '0' + d.getUTCHours().toString() : d.getUTCHours().toString(),
        d.getUTCMinutes().toString().length == 1 ? '0' + d.getUTCMinutes().toString() : d.getUTCMinutes().toString(),
        d.getUTCSeconds().toString().length == 1 ? '0' + d.getUTCSeconds().toString() : d.getUTCSeconds().toString()].join(':');
    return dformat;
  }

  dateFormatter(date, format) {
    if (date == undefined || date == "" || date == 'Invalid Date') {
      return "N/A";
    }
    let dateObj = new Date(date);
    if (dateObj.toString() != 'Invalid Date') {
      return this.format(dateObj, format);
    }
    else {
      return "N/A";
    }
  }

  /** Date Formatter :: Start */


  /**
   * format
   * @param  {Date} target date object
   *         {String} pattern
   *  Y : A full numeric representation of a year, 4 digits
   *  y : A two digit representation of a year
   *  m : Numeric representation of a month, with leading zeros
   *  n : Numeric representation of a month, without leading zeros
   *  F : A full textual representation of a month, such as January or March
   *  M : A short textual representation of a month, three letters
   *  O : Japanese old month name
   *  d : Day of the month, 2 digits with leading zeros
   *  j : Day of the month without leading zeros
   *  w : Numeric representation of the day of the week
   *  l : A full textual representation of the day of the week
   *  D : A textual representation of a day, three letters
   *  N : ISO-8601 numeric representation of the day of the week
   *  J : A Japanese textual representation of the day of the week
   *  g : 12-hour format of an hour without leading zeros
   *  G : 24-hour format of an hour without leading zeros
   *  h : 12-hour format of an hour with leading zeros
   *  H : 24-hour format of an hour with leading zeros
   *  i : Minutes with leading zeros
   *  s : Seconds, with leading zeros
   *  a : Lowercase Ante meridiem and Post meridiem (am or pm)
   *  A : Uppercase Ante meridiem and Post meridiem ��AM or PM��
   *  S : English ordinal suffix for the day of the month, 2 characters
   *  z : The day of the year (starting from 0)
   *  t : Number of days in the given month
   *  L : Whether it's a leap year
   *  * : Mili seconds
   *  Escape character is #. Example: DateFormatter.format(new Date(), "#Y#m#d #i#s Ymd");
   * @return {String} formatted date
   */
  format(d, pattern) {
    if (typeof pattern != "string") return;
    var dYear = d.getFullYear();
    var dMonth = d.getMonth();
    var dDate = d.getDate();
    var dDay = d.getDay();
    var dHours = d.getHours();
    var dMinutes = d.getMinutes();
    var dSeconds = d.getSeconds();
    var dMiliSeconds = d.getMilliseconds();
    var res = "";
    for (var i = 0, len = pattern.length; i < len; i++) {
      var c = pattern.charAt(i);
      switch (c) {
        case "#":
          if (i == len - 1) break;
          res += pattern.charAt(++i);
          break;
        case "Y": res += dYear; break;
        case "y": res += dYear.toString().substr(2, 2); break;
        case "m": res += this.preZero(dMonth + 1); break;
        case "n": res += dMonth + 1; break;
        case "d": res += this.preZero(dDate); break;
        case "j": res += dDate; break;
        case "w": res += dDay; break;
        case "H": res += this.preZero(dHours); break;
        case "G": res += dHours; break;
        case "i": res += this.preZero(dMinutes); break;
        case "s": res += this.preZero(dSeconds); break;
        case "*": res += this.preZero(dMiliSeconds); break;
        default: res += c; break;
      }
    }
    return res;
  }


  preZero(value) {
    return (parseInt(value) < 10) ? "0" + value : value;
  }

  lowerCase(value) {
    if (value != undefined) {
      return value.toLowerCase();
    } else {
      return value;
    }
  }
  trimObjVal(obj) {
    let _self = this;
    if (!Array.isArray(obj) && typeof obj != 'object') return obj;
    return Object.keys(obj).reduce(function(acc, key) {
      acc[key.trim()] = typeof obj[key] == 'string'? obj[key].trim() : obj[key] instanceof Array? obj[key]: obj[key];
      return acc;
    }, Array.isArray(obj)? []:{});
  }

}